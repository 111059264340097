import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { dialogStyles } from "../game/gameDetailDialog/style";
import { ColorfulButton } from "../../atoms/ColorfulButton";
import { ResponseUserInfoDto } from "../../../../../ts/interface/userInfo/interfaces/response-userInfo.interface";
import { UserInfoResponseEntity } from "../../../../../ts/interface/userInfo/entities/userInfo-api.entity";
import { reqGetUserInfoDetail } from "../../../../../ts/api/userInfo";
import { timeToStringFull } from "../../../../../ts/extension/date";
import {useRecoilState} from 'recoil';
import {userInfoDialogState} from '../../../../../ts/stores/slices/dialogOpen';
import {changeDateToTokyoDate} from '../../../../../ts/serveice/date';

interface UserInfoDetailDialogProp {
  userInfoId: number;
}

const UserInfoDetailDialog = (prop: UserInfoDetailDialogProp) => {
  const classes = dialogStyles();

  const [userInfoDetail, setUserInfoDetail] = useState<ResponseUserInfoDto>(
    new ResponseUserInfoDto()
  );
  const setUserInfoDialogState = useRecoilState(userInfoDialogState)[1];

  const getUserInfoDetail = async () => {
    const userInfoId = { userInfoId: prop.userInfoId };
    const r = await reqGetUserInfoDetail(userInfoId);
    const result: UserInfoResponseEntity = r.data;
    if (result.code === 200) {
      setUserInfoDetail(result.data[0]);
    }
  };

  useEffect(() => {
    (async () => {
      await getUserInfoDetail();
    })();
  }, []);

  const closeDialog = () => {
    setUserInfoDialogState(false);
  };
  return (
    <div id="UserInfoDetailDialog">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                User Id
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.id}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Email
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.email}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                User Name
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.user_name}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Email Verified
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.email_verified}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Locale
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.locale}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Picture
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.picture}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                subscriber
              </TableCell>
              <TableCell className={classes.tableTd}>
                {userInfoDetail.subscriber}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Create Time
              </TableCell>
              <TableCell className={classes.tableTd}>
                {timeToStringFull(changeDateToTokyoDate(userInfoDetail.create_time))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.dialogButtonsMain}>
        <ColorfulButton onClick={closeDialog}>OK</ColorfulButton>
      </div>
    </div>
  );
};

export default UserInfoDetailDialog;
