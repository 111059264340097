// 获取Admin list
import { GetAdminDto } from 'src/ts/interface/admin/interfaces/get-admin.interface';
import ajaxRequest, {requestType} from '../../extension/ajax/request';

export const reqGetAdmin = async (data: GetAdminDto) =>
  await ajaxRequest('/admin/list', data, requestType.GET);

// 获取Admin detail
export const reqGetAdminDetail = async (data: any) =>
  await ajaxRequest(`/admin/${data.id}`, data, requestType.GET);

// 作成Admin
export const reqPostCreateAdmin = async (data: any) =>
  await ajaxRequest('/admin/create', data, requestType.POST);

// 删除Admin
export const reqDeleteAdmin = async (data: any) =>
  await ajaxRequest(`/admin/delete/${data.id}`, data, requestType.GET);
