import ajaxRequest, { requestType } from "src/ts/extension/ajax/request"
import { GetCampaignsDto } from "src/ts/interface/campaign/interfaces/get-campaigns.interface";

export const reqGetCampaigns = async (data: GetCampaignsDto) =>
    await ajaxRequest("/campaign/lists", data, requestType.GET);

export const reqGetGameOptions = async () =>
    await ajaxRequest("/campaign/options/game", {}, requestType.GET);

export const reqPostEditCampaign = async (data: object) =>
    await ajaxRequest("/campaign/edit", data, requestType.POST);

export const reqGetCampaignDetail = async (id: string) =>
    await ajaxRequest(`/campaign/detail/${id}`, {}, requestType.GET);

export const reqGetDeleteCampaign = async (id: string) =>
    await ajaxRequest(`campaign/delete/${id}`, {}, requestType.GET);