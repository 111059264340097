import React, { useState, useEffect } from 'react';
import { listStyles } from "src/tsx/views/pages/game/style";
import FormComponent from "../../../umi/FormComponent";
import { reqGetCampaignDetail, reqGetGameOptions, reqPostEditCampaign } from 'src/ts/api/campaign';
import { formatDateString, toCamelCase } from 'src/ts/utils/string';
import { useToast } from 'src/tsx/utils/toast';

const CampaignEditDialog = ({ editRow, onClose }) => {
    const toast = useToast();
    const classes = listStyles();

    const stateOptions = [
        { label: "Pending Review", value: 0 },
        { label: "Active", value: 1 },
        { label: "Ended", value: 2 },
    ];

    const contentTypeOptions = [
        { label: "url", value: "url" },
        { label: "text", value: "text" },
    ];

    const initialFormData = [
        { name: "id", value: "", type: "text", required: true },
        { name: "gameId", value: "", type: "select", options: [], required: true },
        { name: "image", value: "", type: "upload", required: false },
        { name: "title", value: "", type: "text", required: true },
        { name: "content", value: "", type: "textarea", required: true },
        { name: "participation", value: "", type: "text", required: true },
        { name: "reward", value: "", type: "text", required: true },
        { name: "startTime", value: "", type: "date", required: true },
        { name: "endTime", value: "", type: "date", required: true },
        { name: "state", value: "", type: "select", options: stateOptions, required: true },
        { name: "url", value: "", type: "text", required: true },
        { name: "contentType", value: "", type: "select", options: contentTypeOptions, required: true }
    ];

    const [formData, setFormData] = useState(
        editRow && editRow.id > 0
            ? initialFormData
            : initialFormData.filter(field => field.name !== "id")
    );

    const getGameOptions = async () => {
        const r = await reqGetGameOptions();
        if (r.data !== null) {
            if (r.data.code === 200) {
                const optionsGame = r.data.data; // [{label:'', value:''}]
                setFormData(prevFormData =>
                    prevFormData.map(field =>
                        field.name === "gameId" ? { ...field, options: optionsGame } : field
                    )
                );
                console.log("formData", formData);
            }
        }
    };

    const getCampaignDetail = async (id: string) => {
        const r = await reqGetCampaignDetail(id);
        if (r.data !== null) {
            if (r.data.code === 200) {
                const details = r.data.data;
                if (details !== null) {
                    console.log("details", details);
                    details.startTime = formatDateString(details.startTime);
                    details.endTime = formatDateString(details.endTime);
                    setFormData(prevFormData => {
                        const updatedFormData = prevFormData.map(field => ({
                            ...field,
                            value: details[field.name] || ""
                        }));
                        // Add or update the id field at the first position
                        const idField = { name: "id", value: details['id'], type: "text", required: true };
                        return [idField, ...updatedFormData.filter(field => field.name !== "id")];
                    });
                }
            }
        }
    };

    useEffect(() => {
        getGameOptions();
    }, []);

    useEffect(() => {
        console.log("useEffect", editRow);
        if (editRow > 0) {
            getCampaignDetail(editRow);
        } else {
            setFormData(
                initialFormData.filter(field => field.name !== "id").map(field => ({ ...field, value: "" }))
            );
        }
    }, [editRow]);

    const handleSubmit = async (formState) => {
        // 检查必填字段是否为空
        for (const field of formData) {
            if (field.required && !formState[field.name]) {
                alert(`${field.name} is required`);
                return;
            }
        }

        const transformedState = Object.keys(formState).reduce((acc, key) => {
            acc[toCamelCase(key)] = formState[key];
            return acc;
        }, {});

        console.log('Form submitted with state:', transformedState);
        const r = await reqPostEditCampaign(transformedState);
        // 在这里处理表单提交
        if (r.data) {
            const code = r.data.code;
            const data = r.data.data;
            if (code === 200) {
                toast.showToast("Success Edit", "success");
                onClose(); // 成功后关闭 Dialog 并刷新父组件
            } else {
                toast.showToast(r.data.message, "error");
            }
        }
    };

    return (
        <div id="campaignsEdit" className={classes.root} style={{ width: "500px", padding: "30px" }}>
            <FormComponent formData={formData} onSubmit={handleSubmit} />
        </div>
    );
};

export default CampaignEditDialog;