import Homepage from "../../tsx/views/pages/homepage";
import {
  ADMIN,
  DEFAULT_URL,
  DEVELOPER,
  GAME,
  GAME_ITEM,
  HOMEPAGE,
  LOGIN,
  NFT_ITEM,
  OFFER,
  RELAYCALL,
  So,
  TASK,
  TRANS,
  USER_INFO,
  WALLET,
  CAMPAIGNS,
} from "./url";
import User from "../../tsx/views/pages/userInfo/UserInfo";
import Game from "../../tsx/views/pages/game";
import LoginPage from "../../tsx/views/pages/login";
import SO from "src/tsx/views/pages/So";
import GameItem from "src/tsx/views/pages/gameItem";
import AdminPage from "src/tsx/views/pages/admin/Admin";
import DeveloperPage from "src/tsx/views/pages/developer";
import NftItemPage from "src/tsx/views/pages/nftItem";
import TransactionsPage from "src/tsx/views/pages/transaction/Transactions";
import OfferPage from "src/tsx/views/pages/offer/Offer";
import RelayCall from "src/tsx/views/pages/relayCall/RelayCall";
import BatchMint from "src/tsx/views/pages/batchmint/BatchMint";
import WalletPage from "src/tsx/views/pages/wallet/Wallet";
import CampaignsPage from "src/tsx/views/pages/campaigns/Campaigns";

export interface Router {
  path: string;
  exact: boolean;
  text?: string;
  subtext?: string;
  render: () => JSX.Element;
}

const routers: Router[] = [
  {
    path: LOGIN,
    exact: false,
    render: LoginPage,
  },
  {
    path: HOMEPAGE,
    exact: false,
    render: Homepage,
  },
  {
    path: DEFAULT_URL,
    exact: true,
    render: Homepage,
  },
];
const homepageRouters: Router[] = [
  {
    path: USER_INFO,
    exact: false,
    render: User,
  },
  {
    path: GAME,
    exact: false,
    render: Game,
  },
  {
    path: GAME_ITEM,
    exact: true,
    render: GameItem,
  },
  {
    path: So,
    exact: false,
    render: SO,
  },
  {
    path: ADMIN,
    exact: false,
    render: AdminPage,
  }, {
    path: DEVELOPER,
    exact: false,
    render: DeveloperPage,
  }, {
    path: NFT_ITEM,
    exact: false,
    render: NftItemPage,
  }, {
    path: TRANS,
    exact: false,
    render: TransactionsPage,
  }, {
    path: OFFER,
    exact: false,
    render: OfferPage,
  },
  {
    path: RELAYCALL,
    exact: false,
    render: RelayCall,
  },
  {
    path: TASK,
    exact: false,
    render: BatchMint,
  },
  {
    path: WALLET,
    exact: false,
    render: WalletPage,
  },
  {
    path: CAMPAIGNS,
    exact: false,
    render: CampaignsPage,
  },


];

export { routers, homepageRouters };
