import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { listStyles } from "../game/style";
import '../../../style/app.scss'
/*  添加 自动生成游戏secret
    修改,一览,详细
 */
const WalletPage = () => {
    const classes = listStyles();

    const wallets = [{
        name: "Umi Wallet",
        address: '0x53b0E88DEf742f631409035dbaA277049612A751'
    }, {
        name: "Reward Pool",
        address: '0xe57be407A99fd55DDdE5F84C8BbF088148562e2b'
    }, {
        name: "Contract Creator",
        address: '0x3EFfd968A92C86336CE4CE5e0A16373DDDDB385E'
    }, {
        name: "Gas Fee Pay",
        address: '0x86152c6aD36a330F7396B91D4D7b0E94638478B9'
    }, {
        name: "Umi Test Fund (EVM)",
        address: "0xa62dc7F2F1BE62A56EB1675D5DdD19aA50f16B4d"
    }, {
        name: "Umi Test Fund (TRON)",
        address: "TKtD25ziRX8uSa8KTebBTFLW9YjADp11kg"
    }, {
        name: "Umi Test User1",
        address: "0xfD31CcA7cEF297E7548d1FC0cB77bC97F7772B7b"
    }, {
        name: "Umi Test User2",
        address: "0x32bdb92A3367c48030349bbB50c39B50bE7B37cf"
    }]

    const getDeBankLink = (address: string) => {
        return `https://debank.com/profile/${address}`
    }

    return (
        <div id="Wallet" className={classes.root}>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>Purpose</TableCell>
                            <TableCell className={classes.tableTh}>Wallet Address</TableCell>
                            <TableCell className="right">Link</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {wallets.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.name}
                                </TableCell>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.address}
                                </TableCell>
                                <TableCell scope="row" className="right">
                                    <a className="btn btn-default" target="_blank" href={getDeBankLink(row.address)}>Detail</a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default WalletPage;
