import {
    Box,
    Button,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    Fade,
    Paper,
    Popper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Modal,
    SelectChangeEvent,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import { listStyles } from "../game/style";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import dayjs from "dayjs";
import { useEffect } from "react";
import { UpdateNftMintTask } from "src/ts/interface/batchmint/interfaces/update-nft-mint-task.interface";
import { GetNftMintTaskDto } from "src/ts/interface/batchmint/interfaces/get-nftMintTask.interface";
import { reqDeleteTask, reqGetTask } from "src/ts/api/nftMintTask";
import MyPagination from "../../components/modules/myPagination";
import { getSupportChains } from "src/ts/config/Web3AuthConfig";
import { CommonResponse } from "src/ts/interface/common.response";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function formatStatus(status: number) {
    if (status === 2) return 'Error';
    if (status === 0) return 'Pending';
    if (status === 1) return 'Finish';
    return 'Unknown';
}

const BatchMint = () => {
    const [page, setPage] = useState(1);
    // 分页的页数总数
    const [count, setCount] = useState<number>(0);
    // 分页每一页的页数
    const [pageRows, setPageRows] = useState<string>("200");

    const [batchmintList, setBatchmintList] = useState<UpdateNftMintTask[]>([]);
    const [nftmintTaskItem, setNftmintTaskItem] = useState<UpdateNftMintTask>();

    const [consoleAccountIdSearch, setConsoleAccountIdSearch] = useState('');
    const [chainSearch, setChainSearch] = useState('');
    const [stateSearch, setStateSearch] = useState('');

    const [chains, setChains] = useState([]);

    const classes = listStyles();

    const supportChains = () => {
        const chains = getSupportChains();
        console.log("chains", chains);
        setChains(chains);
    }

    const detail = async (batchmintItem: UpdateNftMintTask) => {
        setNftmintTaskItem(batchmintItem);
        setOpen(true);
    }

    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const getBatchMintList = async () => {

        const getRelayCallData = new GetNftMintTaskDto(
            consoleAccountIdSearch,
            chainSearch,
            stateSearch,
            page,
            parseInt(pageRows),
        );
        const r = await reqGetTask(getRelayCallData);

        const result = r.data;
        if (result.code === 200) {
            setBatchmintList(result.data);
            const pageCount = Math.ceil(result.count / parseInt(pageRows));
            setCount(pageCount);
        }
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getBatchMintList();
    }, [page, pageRows])

    useEffect(() => {
        supportChains();
    }, [])

    const handleSearchButtonClick = () => {
        setPage(1);
        getBatchMintList();
    }

    const formatResult = (json: string) => {
        const result = JSON.parse(json);
        if (result.state) {
            return `success, hash: ${result.data}`;
        } else {
            return `fail, reason: ${result.message}`;
        }
    }

    const deleteTask = async (id: number) => {
        const r = await reqDeleteTask({ id: id });
        console.log(r);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            getBatchMintList();
        }
    }

    return (
        <div id="RelayCall" className={classes.root}>
            <div className={classes.searchInputMain}>
                <TextField
                    id="AccountUid"
                    label="Account Uid"
                    variant="standard"
                    value={consoleAccountIdSearch}
                    onChange={(e) => setConsoleAccountIdSearch(e.target.value)}
                    style={{ marginLeft: 10 }}
                />
                <FormControl variant="standard" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="Status-Label">Chain</InputLabel>
                    <Select
                        labelId="Chain"
                        id="Chain"
                        value={chainSearch}
                        onChange={(e) => {
                            setChainSearch(e.target.value);
                        }}
                        label="Chain"
                    >
                        <MenuItem value="" key="">
                            <em>None</em>
                        </MenuItem>
                        {chains.map((row) => (
                            <MenuItem key={row.chainId} value={row.chainName}><em>{row.displayName}</em></MenuItem>
                        ))};
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="Status-Label">Status</InputLabel>
                    <Select
                        labelId="State"
                        id="State"
                        value={stateSearch}
                        onChange={(e) => {
                            setStateSearch(e.target.value);
                        }}
                        label="Chain"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>
                            <em>Finish</em>
                        </MenuItem>
                        <MenuItem value={2}>
                            <em>Error</em>
                        </MenuItem>
                        <MenuItem value={0}>
                            <em>Pending</em>
                        </MenuItem>

                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    className={classes.searchInputButton}
                    onClick={handleSearchButtonClick}
                >
                    Search
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>Id</TableCell>
                            <TableCell className={classes.tableTh}>Chain</TableCell>
                            <TableCell className={classes.tableTh}>Account</TableCell>
                            <TableCell className={classes.tableTh}>Status</TableCell>
                            <TableCell className={classes.tableTh}>Result</TableCell>
                            <TableCell className={classes.tableTh}>Ctime</TableCell>
                            <TableCell className={classes.tableTh}>Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {batchmintList.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.id}
                                </TableCell>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.chain}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {row.consoleAccountId}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {formatStatus(row.state)}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <p style={{ wordWrap: 'break-word', width: '600px', margin: '0 auto', fontSize: '10px' }}>{formatResult(row.tx)}</p>
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss')}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <Button onClick={() => detail(row)}>
                                        Detail
                                    </Button>
                                    <Button onClick={() => deleteTask(row.id)}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div>
                <MyPagination
                    count={count}
                    page={page}
                    pageRows={pageRows}
                    handlePageChange={handlePageChange}
                    handlePageRowsChange={handlePageRowsChange}
                />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 1024 }}>
                    <h2 id="parent-modal-title">Task Detail</h2>
                    {nftmintTaskItem !== null && typeof (nftmintTaskItem) !== 'undefined' ? (
                        <Box className='modal-detail'>
                            <label className="label">chain</label>
                            <p>{nftmintTaskItem.chain}</p>
                            <label className="label">post data</label>
                            <p>{nftmintTaskItem.jsondata}</p>
                            <label className="label">result</label>
                            <p>{formatResult(nftmintTaskItem.tx)}</p>
                            <label className="label">game`s api return</label>
                            <p>{nftmintTaskItem.gameapi}</p>

                        </Box>
                    ) : (<Box>no data</Box>)}
                    <ColorfulButton onClick={() => handleClose()}>Close</ColorfulButton>
                </Box>
            </Modal>
        </div>
    )
}
export default BatchMint;