import React, { ChangeEvent, useEffect } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Box,
    FormHelperText
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { getFileExtension } from 'src/ts/serveice/file';
import { uploadImgToS3 } from 'src/ts/serveice/s3';
import { useToast } from 'src/tsx/utils/toast';

interface FormComponentProps {
    formData: {
        name: string;
        value: string;
        type: string;
        options?: { value: string | number; label: string }[];
        required?: boolean;
    }[];
    onSubmit: (formState: Record<string, any>) => void;
}

const FormComponent: React.FC<FormComponentProps> = ({ formData, onSubmit }) => {
    const [formState, setFormState] = React.useState<Record<string, any>>({});
    const [errors, setErrors] = React.useState<Record<string, boolean>>({});

    const toast = useToast();

    useEffect(() => {
        const initialState: Record<string, any> = {};
        formData.forEach(field => {
            initialState[field.name] = field.value;
        });
        setFormState(initialState);
    }, [formData]);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type, files } = event.target as HTMLInputElement;
        setFormState({
            ...formState,
            [name]: type === 'file' ? files?.[0] : value
        });
        setErrors({ ...errors, [name]: false });
    };

    const handleSelectChange = (event: SelectChangeEvent<any>) => {
        const { name, value } = event.target;
        setFormState({
            ...formState,
            [name]: value
        });
        setErrors({ ...errors, [name]: false });
    };

    const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>, inputName: string) => {
        const { files } = e.target;

        if (files && files.length !== 0) {
            const fileName = files[0].name;
            const fileExtension = getFileExtension(fileName);
            if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg') {
                const imgUrl = await uploadImgToS3(files[0]);
                if (imgUrl) {
                    setFormState({
                        ...formState,
                        [inputName]: imgUrl
                    });
                } else {
                    toast.showToast('Fail to upload, please try again', "error");
                }
            } else {
                toast.showToast('Can only upload images with jpg, jpeg, png', "error");
            }
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        let valid = true;
        const newErrors: Record<string, boolean> = {};

        formData.forEach(field => {
            if (field.required && !formState[field.name]) {
                valid = false;
                newErrors[field.name] = true;
            }
        });

        if (!valid) {
            setErrors(newErrors);
            toast.showToast('Please fill in all required fields.', "error");
            return;
        }

        onSubmit(formState);
    };

    useEffect(() => {
        console.log("formComponent:::formData", formData);
        console.log("formComponent:::formState", formState);
    }, [formData, formState]);

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
                {formData.map((field, index) => {
                    const isRequired = field.required ? { required: true } : {};
                    const error = errors[field.name];
                    switch (field.type) {
                        case 'text':
                            return (
                                <FormControl key={index} variant="outlined" fullWidth error={error}>
                                    <TextField
                                        label={field.name}
                                        name={field.name}
                                        value={formState[field.name] || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        {...isRequired}
                                    />
                                    {error && <FormHelperText>This field is required</FormHelperText>}
                                </FormControl>
                            );
                        case 'textarea':
                            return (
                                <FormControl key={index} variant="outlined" fullWidth error={error}>
                                    <TextField
                                        label={field.name}
                                        name={field.name}
                                        value={formState[field.name] || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        {...isRequired}
                                    />
                                    {error && <FormHelperText>This field is required</FormHelperText>}
                                </FormControl>
                            );
                        case 'select':
                            return (
                                <FormControl key={index} variant="outlined" fullWidth error={error} {...isRequired}>
                                    <InputLabel>{field.name}</InputLabel>
                                    <Select
                                        name={field.name}
                                        value={formState[field.name] || ""}
                                        onChange={handleSelectChange}
                                        label={field.name}
                                    >
                                        {field.options?.map(option => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {error && <FormHelperText>This field is required</FormHelperText>}
                                </FormControl>
                            );
                        case 'date':
                            return (
                                <FormControl key={index} variant="outlined" fullWidth error={error}>
                                    <TextField
                                        label={field.name}
                                        name={field.name}
                                        type="datetime-local"
                                        value={formState[field.name] || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...isRequired}
                                    />
                                    {error && <FormHelperText>This field is required</FormHelperText>}
                                </FormControl>
                            );
                        case 'upload':
                            return (
                                <FormControl key={index} variant="outlined" fullWidth error={error}>
                                    <TextField
                                        label={field.name}
                                        name={field.name}
                                        type="file"
                                        onChange={(e) => handleFileUpload(e as ChangeEvent<HTMLInputElement>, field.name)}
                                        variant="outlined"
                                        fullWidth
                                        {...isRequired}
                                    />
                                    {error && <FormHelperText>This field is required</FormHelperText>}
                                </FormControl>
                            );
                        default:
                            return null;
                    }
                })}
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </form>
    );
};

export default FormComponent;