import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { nanoid } from 'nanoid';
import { getFileExtension } from './file';

/**
 * Uploads a file to S3.
 * @param file 要上传的图片
 */
export const uploadImgToS3 = async (file: File): Promise<string | null> => {
  // use AWS
  const s3 = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID as string,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY as string,
    },
  });
  const imgId = nanoid();
  const fileName = file.name;
  const fileExtension = getFileExtension(fileName);
  const fileKey = imgId + '.' + fileExtension;

  try {
    const imgData = await s3.send(
      new PutObjectCommand({
        Bucket: process.env.REACT_APP_IMG_BUDGET_NAME,
        Key: fileKey,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
      })
    );
    // 上传完成，取回地址，没有返回null
    if (imgData) {
      console.log(imgData);
      return `${process.env.REACT_APP_IMG_BUDGET_URL}/${fileKey}`;
    }
    return null;
  } catch (e) {
    console.log(e);
    return null;
  }
};
