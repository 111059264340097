export const DEFAULT_URL = "/";
export const LOGIN = "/login";
export const HOMEPAGE = "/homepage";
export const USER_INFO = "/homepage/userInfo";
export const GAME = "/homepage/game";
export const TOURNAMENT_CLASS = "/homepage/tournamentClass";
export const TOURNAMENT = "/homepage/tournament";
export const TOURNAMENT_PLAY = "/homepage/tournamentPlay";
export const PURCHASE = "/homepage/purchase";
export const PAYOUT = "/homepage/payout";
export const MATCH = "/homepage/match";
export const MATCH_PLAY = "/homepage/matchPlay";
export const MATCH_CLASS = "/homepage/matchClass";
export const WITHDRAW_INFO = "/homepage/withdrawInfo";
export const GAME_ITEM = "/homepage/gameItem";
export const CHAT = "/homepage/chat";

export const CHAT_PUBLIC = "/homepage/chat/public";
export const CHAT_DIRECT = "/homepage/chat/direct";
export const CHAT_CS = "/homepage/chat/cs";
export const BULK_CS = "/homepage/chat/bulkCs";

export const ENERGY_BALANCE = "/homepage/energyBalance";

export const ENERGY_BILL = "/homepage/energyBill";

export const TWITTER_ACCOUNT = "/homepage/twitterAccount";

export const TWITTER_ARTICLE = "/homepage/twitterArticle";

export const TARGET_TWITTER = "/homepage/targetTwitter";

export const So = "/homepage/so";


// new
export const NFT_ITEM = "/homepage/nftItem";
export const TRANS = "/homepage/trans";
export const OFFER = "/homepage/offer";
export const ADMIN = "/homepage/admin";
export const DEVELOPER = "/homepage/developer";

export const RELAYCALL = "/homepage/relaycall";
export const TASK = "/homepage/task";
export const WALLET = "/homepage/wallet";
export const CAMPAIGNS = "/homepage/campaigns";



// back to /login and delete the token
export const backToLogin = () => {
  window.location.href = "/#/login";
};
