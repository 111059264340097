export const MARGIN_LEFT_AND_RIGHT_AUTO = {
  marginLeft: "auto",
  marginRight: "auto",
};
export const FLEX_ALL_CENTER = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
export const FLEX_WIDTH_CENTER = {
  display: "flex",
  justifyContent: "center",
};
export const FLEX_HEIGHT_CENTER = {
  display: "flex",
  alignItems: "center",
};

export const TEXT_ALIGN_CENTER = {
  textAlign: "center",
} as const;

export const SECOND_THEME_BACKGROUND_COLOR = "rgba(33, 40, 63, 1)";
