// CampaignsPage.js
import React, { ChangeEvent, useEffect, useState } from "react";
import { listStyles } from "../game/style";
import TableComponent from '../../components/umi/TableComponent';
import { Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Button, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CampaignEditDialog from '../../components/modules/game/campaignEditDialog';
import { GetCampaignsDto } from 'src/ts/interface/campaign/interfaces/get-campaigns.interface';
import { reqGetCampaigns, reqGetDeleteCampaign } from 'src/ts/api/campaign';
import MyPagination from '../../components/modules/myPagination';
import { SelectChangeEvent } from "@mui/material/Select";

const CampaignsPage = () => {
    const classes = listStyles();
    const [campaigns, setCampaigns] = useState([]);

    const [edit, setEdit] = useState(false); // 初始化为 false
    const [editRow, setEditRow] = useState(0);

    const handleEdit = (row) => {
        console.log('Edit button clicked for row:', row);
        setEditRow(row.id);
        setEdit(true);
    };

    const handleDelete = async (row) => {
        console.log('Delete button clicked for row:', row);
        await reqGetDeleteCampaign(row.id);
        setCampaigns(prevCampaigns => prevCampaigns.filter(campaign => campaign.id !== row.id));
    };

    const handleAdd = () => {
        setEditRow(0);
        setEdit(true);
    };

    const [page, setPage] = useState(1);
    const [count, setCount] = useState<number>(0);
    const [pageRows, setPageRows] = useState<string>("200");
    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };

    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleCloseDialog = () => {
        setEdit(false);
        getDataList(); // 刷新列表
    };

    const getDataList = async () => {
        const getCampaignsData = new GetCampaignsDto(page, parseInt(pageRows));
        const r = await reqGetCampaigns(getCampaignsData);
        const result = r.data;
        if (result.code === 200) {
            setCampaigns(result.data);
        }
        const pageCount = Math.ceil(result.count / parseInt(pageRows));
        setCount(pageCount);
    }
    useEffect(() => {
        const pageCount = Math.ceil(count / parseInt(pageRows));
        setCount(pageCount);
        setPage(1);
    }, [pageRows, setCount]);

    useEffect(() => {
        (async () => {
            await getDataList();
        })();
    }, [page, pageRows]);


    return (
        <div id="campaigns" className={classes.root}>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button variant="contained" color="primary" onClick={handleAdd}>
                    ADD
                </Button>
            </Box>
            <TableComponent
                objArray={campaigns}
                classes={classes}
                onEdit={handleEdit}
                onDelete={handleDelete}
                showEdit={true}
                showDelete={true}
            />
            <MyPagination
                count={count}
                page={page}
                pageRows={pageRows}
                handlePageChange={handlePageChange}
                handlePageRowsChange={handlePageRowsChange}
            />
            <Dialog open={edit} onClose={() => setEdit(false)}>
                <DialogTitle>
                    {editRow === 0 ? "New Campaign" : "Edit Campaign"}
                    <IconButton
                        aria-label="close"
                        onClick={() => setEdit(false)}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <CampaignEditDialog editRow={editRow} onClose={handleCloseDialog} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default CampaignsPage;