export class BaseGameDto {
  nid: string;
  name: string;
  shortName: string;
  category: string;
  slogan: string;
  description: string;
  introduction: string;
  iconUrl: string;
  faviconUrl: string;
  screenshot: string;
  email: string;
  company: string;
  twitter: string;
  facebook: string;
  line: string;
  cdnUrl: string;
  gameServerUrl: string;
  callbackUrl: string;
  state: number;
  highlightType: number;
  orderWeight: number;
  secret: string;
  coverImageUrl: string;
  modifyTime: Date;
  createTime: Date;

  constructor() {
    this.nid = '';
    this.name = '';
    this.shortName = '';
    this.category = '';
    this.slogan = '';
    this.description = '';
    this.introduction = '';
    this.iconUrl = '';
    this.faviconUrl = '';
    this.screenshot = '';
    this.email = '';
    this.company = '';
    this.twitter = '';
    this.facebook = '';
    this.line = '';
    this.cdnUrl = '';
    this.gameServerUrl = '';
    this.callbackUrl = '';
    this.state = 0;
    this.highlightType = 0;
    this.orderWeight = 0;
    this.secret = '';
    this.coverImageUrl = '';
    this.modifyTime = new Date();
    this.createTime = new Date();
  }
}