import { GetReqDto } from "../../common";

export class GetRelayCallDto extends GetReqDto {
  chainIdSearch: string;
  targetSearch: string;
  userSearch: string;
  taskStateSearch: string;
  transactionHashSearch: string;

  constructor(
    chainIdSearch: string,
    targetSearch: string,
    userSearch: string,
    taskStateSearch: string,
    transactionHashSearch: string,
    page: number, pageRows: number) {
    super(page, pageRows);
    this.chainIdSearch = chainIdSearch;
    this.targetSearch = targetSearch;
    this.userSearch = userSearch;
    this.taskStateSearch = taskStateSearch;
    this.transactionHashSearch = transactionHashSearch;
  }
}


export interface RelayCallSelectSearch {
  chainId?: number;
  target?: string;
  user?: string;
  taskState?: string;
  transactionHash?: string;
}

export function getRelayCallSearchCondition(getRelayCallDto: GetRelayCallDto) {
  const search: RelayCallSelectSearch = {};

  const chainIdSearch = +getRelayCallDto.chainIdSearch;
  const targetSearch = getRelayCallDto.targetSearch;
  const userSearch = getRelayCallDto.userSearch;
  const taskStateSearch = getRelayCallDto.taskStateSearch;
  const transactionHashSearch = getRelayCallDto.transactionHashSearch;

  if (chainIdSearch) {
    search.chainId = chainIdSearch;
  }
  if (targetSearch) {
    search.target = targetSearch;
  }
  if (userSearch) {
    search.user = userSearch;
  }
  if (taskStateSearch) {
    search.taskState = taskStateSearch;
  }
  if (transactionHashSearch) {
    search.transactionHash = transactionHashSearch;
  }

  return search;
}
