export class BaseNftItemDto {
  itemdefid: string;
  animationUrl: string;
  externalUrl: string;
  gameId: number;
  gasFee: number;
  description: string;
  image: string;
  itemType: string;
  lockState: string;
  name: string;
  owner: string;
  price: string;
  priceChange: number;
  suggestedPrice: number;
  tokenId: number;
  tradeState: number;
  modifyTime: Date;
  createTime: Date;
  consoleAccountId: number;
  orderNo: string;
  chain: string;
  amount: number;
  costs: number;
  contractAddress: string;
  ownerUid: number;
  gameItemId: number;
  attribute: string;
  totalCollection: number;
  priceType: string;
  attributes?: string;


  constructor() {
    this.itemdefid = '';
    this.animationUrl = '';
    this.externalUrl = '';
    this.gameId = 0;
    this.gasFee = 0;
    this.description = '';
    this.image = '';
    this.itemType = '';
    this.lockState = '';
    this.name = '';
    this.owner = '';
    this.price = '0';
    this.priceChange = 0;
    this.suggestedPrice = 0;
    this.tokenId = 0;
    this.tradeState = 0;
    this.modifyTime = new Date();
    this.createTime = new Date();
    this.consoleAccountId = 0;
    this.orderNo = '';
    this.chain = '';
    this.amount = 0;
    this.costs = 0;
    this.contractAddress = '';
    this.ownerUid = 0;
    this.gameItemId = 0;
    this.attribute = '';
    this.totalCollection = 0;
    this.priceType = '';
    this.attributes = '';
  }
}
