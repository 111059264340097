import { fromDecimal, toDecimal } from "../utils/helper";
import { ChainData, chains } from "./chain";
import { contracts } from "./contract";
import { tokens } from "./token";

export interface ChainInterface {
    chain: string,
    rpc: string,
    usdt: string,
    market: string,
    nft: string,
    chainIdNumber: number,
    blockExlorer: string,
    name: string,
    value: string
}
export function getChainId(chain: string) {
    console.log("getChainId", chain);
    const chainConfig = getChain(chain);
    return chainConfig.chainId;
}

export function getChain(chain: string) {
    const chainConfig = chains[chain];
    return chainConfig;
}

export function getToken(chain: string, name: string) {
    const chainId = getChainId(chain);
    console.log("tokens", tokens, name.toLowerCase(), chainId);
    const token = tokens[name.toLowerCase()][chainId];
    return token;
}

export function getTokenAddress(chain: string, name: string) {
    const token = getToken(chain, name);
    return token.address;
}

export function getTokenDecimal(chain: string, name: string) {
    if (name === 'divepoint') return 0;
    const token = getToken(chain, name);
    return token.decimal;
}

export function getContract(chain: string, name: string) {
    const chainId = getChainId(chain);
    // const contract = contracts[chain];
    return contracts[name][chainId];
}

export function getContractAddress(chain: string, name: string) {
    const contract = getContract(chain, name);
    return contract.address;
}

export function getWeb3RpcURL(chain: string) {
    const chainConfig = getChain(chain);
    return chainConfig.rpc;
}

export function getMarketAddress(chain: string) {
    return getContractAddress(chain, "market");
}

export function getPresaleAddress(chain: string) {
    return getContractAddress(chain, "presale");
}

// get payment center contract address
export function getPaymentAddress(chain: string) {
    return getContractAddress(chain, "payment");
}

export function fromBigInt(chain: string, price: number, priceType: string) {
    const tokenDecimal = getTokenDecimal(chain, priceType);
    return fromDecimal(price, tokenDecimal);
}

export function toBigInt(chain: string, bigIntPrice: number, priceType: string) {
    const tokenDecimal = getTokenDecimal(chain, priceType);
    return toDecimal(bigIntPrice, tokenDecimal);
}


export function getSupportChains() {
    // const entries = Object.values(supportChains);
    const entries = Object.values(chains).map(item => ({
        chain: item.chain,
        chainId: item.chainId,
        displayName: item.displayName,
        chainName: item.chainName,
        ticker: item.ticker,
        tickerName: item.tickerName,
        blockExplorer: item.blockExplorer,
        rpc: item.rpc,
        logo: item.logo,
        supportToken: item.supportToken
    }));
    console.log(entries);
    return entries;
}

export function getBlockchainExplorerTransactionLink(chain: string, hash: string) {
    return chains[chain].blockExplorer + '/tx/' + hash;
}

export function getChainName(chainId: number): string | undefined {
    const chainData: ChainData = chains;
    for (const key in chainData) {
      if (chainData.hasOwnProperty(key)) {
        const data = chainData[key];
        if (data.chainId === chainId) {
          return data.chainName;
        }
      }
    }
    return undefined;
  }

export function getBlockchainExplorerWalletLink(chain: string, hash: string) {
    if (typeof chain === 'undefined' || typeof hash === 'undefined' || chain === '' || hash === '') return '#';
    return chains[chain].blockExplorer + '/address/' + hash;
}