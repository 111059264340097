
import { GetNftMintTaskDto } from 'src/ts/interface/batchmint/interfaces/get-nftMintTask.interface';
import ajaxRequest, {requestType} from '../../extension/ajax/request';

// 获取task list
export const reqGetTask = async (data: GetNftMintTaskDto) =>
  await ajaxRequest('/task/list', data, requestType.GET);

// 获取task detail
export const reqGetTaskDetail = async (data: any) =>
  await ajaxRequest(`/task/${data.id}`, data, requestType.GET);


// 删除task
export const reqDeleteTask = async (data: any) =>
  await ajaxRequest(`/task/delete/${data.id}`, data, requestType.GET);
