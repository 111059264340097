export function getLocalDateTime(utcDateString: string) {
    // 将 UTC 时间字符串转换为 Date 对象
    const utcDate = new Date(utcDateString);

    // 格式化日期时间为浏览器所在国家的时间
    const localDateString = utcDate.toLocaleString(undefined, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    console.log(localDateString);
    return localDateString;
}