import { GetReqDto } from '../../common';

export class GetNftItemDto extends GetReqDto {
    consoleAccountIdSearch: string;
    chainSearch: string;
    tradeStateSearch: string;
    tokenIdSearch: string;
    ownerUidSearch: string;
    ownerSearch: string;
    gameIdSearfch: string;
    gameItemIdSearch: string;

    constructor(
        consoleAccountIdSearch: string,
        chainSearch: string,
        tradeStateSearch: string,
        tokenIdSearch: string,
        ownerUidSearch: string,
        ownerSearch: string,
        gameIdSearch: string,
        gameItemIdSearch: string,
        page: number, pageRows: number) {
        super(page, pageRows);
        this.consoleAccountIdSearch = consoleAccountIdSearch;
        this.chainSearch = chainSearch;
        this.tradeStateSearch = tradeStateSearch;
        this.tokenIdSearch = tokenIdSearch;
        this.ownerUidSearch = ownerUidSearch;
        this.ownerSearch = ownerSearch;
        this.gameIdSearfch = gameIdSearch;
        this.gameItemIdSearch = gameItemIdSearch;

    }
}

export interface GetNftItemSearch {
    tokenId?: number;
    consoleAccountId?: number;
    chain?: string;
    tradeState?: number;
    ownerUid?: number;
    owner?: string;
    gameId?: number;
    gameItemId?: number;
}

export function getNftItemSearchCondition(getNftItemDto: GetNftItemDto) {
    const search: GetNftItemSearch = {};

    const consoleAccountIdSearch = +getNftItemDto.consoleAccountIdSearch;
    const chainSearch = getNftItemDto.chainSearch;
    const tradeStateSearch = +getNftItemDto.tradeStateSearch;
    const tokenIdSearch = +getNftItemDto.tokenIdSearch;
    const ownerUidSearch = +getNftItemDto.ownerUidSearch;
    const gameIdSearch = +getNftItemDto.gameIdSearfch;
    const gameItemIdSearch = +getNftItemDto.gameItemIdSearch;


    if (consoleAccountIdSearch) {
        search.consoleAccountId = consoleAccountIdSearch;
    }
    if (chainSearch) {
        search.chain = chainSearch;
    }
    if (tradeStateSearch) {
        search.tradeState = tradeStateSearch;
    }
    if (tokenIdSearch) {
        search.tokenId = tokenIdSearch;
    }
    if (ownerUidSearch > 0) {
        search.ownerUid = ownerUidSearch;
    }
    if (gameIdSearch) {
        search.gameId = gameIdSearch;
    }
    if (gameItemIdSearch) {
        search.gameItemId = gameItemIdSearch;
    }

    return search;
}
