import {
  ADMIN,
  DEVELOPER,
  GAME, GAME_ITEM,
  NFT_ITEM,
  OFFER,
  RELAYCALL,
  TASK,
  TRANS,
  USER_INFO,
  WALLET,
  CAMPAIGNS
} from './url';
import User from '../../tsx/views/pages/userInfo/UserInfo';
import Game from '../../tsx/views/pages/game';
import GameItem from '../../tsx/views/pages/gameItem';
import { Router } from './index';
import DeveloperPage from 'src/tsx/views/pages/developer';
import AdminPage from 'src/tsx/views/pages/admin/Admin';
import NftItemPage from 'src/tsx/views/pages/nftItem';
import TransactionsPage from 'src/tsx/views/pages/transaction/Transactions';
import OfferPage from 'src/tsx/views/pages/offer/Offer';
import WalletPage from "src/tsx/views/pages/wallet/Wallet"
import CampaignsPage  from 'src/tsx/views/pages/campaigns/Campaigns';

export const homepageSlideRouters: Router[] = [
  {
    path: USER_INFO,
    exact: true,
    render: User,
    text: "User",
    subtext: 'User',
  },
  {
    path: DEVELOPER,
    exact: true,
    render: DeveloperPage,
    text: "Developer",
    subtext: 'Dev',
  },
  {
    path: ADMIN,
    exact: true,
    render: AdminPage,
    text: "Admin",
    subtext: 'Admin',
  },
  {
    path: GAME,
    exact: true,
    render: Game,
    text: "Game",
    subtext: "Game",
  },
  {
    path: GAME_ITEM,
    exact: true,
    render: GameItem,
    text: "Game Item",
    subtext: 'Item',
  },
  {
    path: NFT_ITEM,
    exact: true,
    render: NftItemPage,
    text: "NFT Item",
    subtext: 'NFT',
  },
  {
    path: TRANS,
    exact: true,
    render: TransactionsPage,
    text: "Transactions",
    subtext: 'Txs',
  },
  {
    path: OFFER,
    exact: true,
    render: OfferPage,
    text: "Offer",
    subtext: 'Offer'
  },
  {
    path: RELAYCALL,
    exact: true,
    render: OfferPage,
    text: "Relay Call",
    subtext: 'Relay'
  },
  {
    path: TASK,
    exact: true,
    render: OfferPage,
    text: "Batch Mint Task",
    subtext: 'Task'
  },
  {
    path: WALLET,
    exact: true,
    render: WalletPage,
    text: "Wallet",
    subtext: 'Wallet'
  }, {
    path: CAMPAIGNS,
    exact: true,
    render: CampaignsPage,
    text: "Campaigns",
    subtext: 'Camps'
  },

];