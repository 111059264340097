/**
 *  取得文件的拓展名
 * @param fileName
 */
export const getFileExtension = (fileName: string): string | null => {
  const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
  if (ext) {
    return ext;
  }
  return null;
};
