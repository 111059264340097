import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { listStyles } from "./style";
import { SelectChangeEvent } from "@mui/material/Select";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import MyPagination from "../../components/modules/myPagination";
import GameDetailDialog from "../../components/modules/game/gameDetailDialog";
import { GetGameDto } from "../../../../ts/interface/game/interfaces/get-game.interface";
import { GameResponseEntity } from "../../../../ts/interface/game/entities/game-api.entity";
import { ResponseGameDto } from "../../../../ts/interface/game/interfaces/response-game.interface";
import { reqGetGame } from "../../../../ts/api/game";
import { useRecoilState } from "recoil";
import { TEXT_ALIGN_CENTER } from "../../../style/globalStyle";
import { dpSendDialogState, gameDialogState } from "../../../../ts/stores/slices/dialogOpen";
import { grey } from "@mui/material/colors";
import { socket } from "../../../../ts/extension/ajax/request";
import DpSendDialog from "../../components/modules/game/dpSendDialog";

/*  添加 自动生成游戏secret
    修改,一览,详细
 */
const GamePage = () => {
  const classes = listStyles();
  // const socket = io(host);
  const [page, setPage] = useState(1);
  // 分页的页数总数
  const [count, setCount] = useState<number>(0);
  // 分页每一页的页数
  const [pageRows, setPageRows] = useState<string>("200");
  const [gameList, setGameList] = useState<ResponseGameDto[]>([]);
  const [gameDetailOpen, setGameDetailOpen] = useRecoilState(gameDialogState);
  const [dpSendOpen, setDpSendOpen] = useRecoilState(dpSendDialogState);
  const [gameDetailId, setGameDetailId] = useState<number>(1);

  const handlePageRowsChange = (event: SelectChangeEvent) => {
    setPageRows(event.target.value as string);
    setPage(1);
  };
  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    (async () => {
      await getGameList();
    })();
  }, [page, pageRows]);
  // 当每页大小改变的时候，重新计算页数
  useEffect(() => {
    const pageCount = Math.ceil(count / parseInt(pageRows));
    setCount(pageCount);
    setPage(1);
  }, [pageRows, setCount]);

  const getGameList = async () => {
    const getGameData = new GetGameDto(page, parseInt(pageRows));
    const r = await reqGetGame(getGameData);
    const result: GameResponseEntity = r.data;
    if (result.code === 200) {
      setGameList(result.data);
    }
    const pageCount = Math.ceil(result.count / parseInt(pageRows));
    setCount(pageCount);
  };

  const getGameDetail = (id: number) => {
    setGameDetailId(id);
    setGameDetailOpen(true);
  };

  const dpSend = (id: number) => {
    setGameDetailId(id);
    setDpSendOpen(true);
  };

  const addNewGame = () => {
    setGameDetailId(0);
    setGameDetailOpen(true);
  };
  const handleGameDetailClose = () => {
    setGameDetailOpen(false);
  };
  const handleDpSendClose = () => {
    setDpSendOpen(false)
  };

  useEffect(() => {
    // 当game有更新或者添加时，刷新
    socket.on("getGameListToClient", (game: any) => {
      getGameList();
    });
  }, []);

  return (
    <div id="Game" className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTh}>Id</TableCell>
              <TableCell className={classes.tableTh}>Name</TableCell>
              <TableCell className={classes.tableTh}>Short Name</TableCell>
              <TableCell className={classes.tableTh}>Category</TableCell>
              <TableCell className={classes.tableTh}>Company</TableCell>
              <TableCell className={classes.tableTh}>Order Weight</TableCell>
              <TableCell className={classes.tableTh}>Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameList.map((row) => (
              <TableRow
                key={row.id}
                style={row.state === 0 ? { backgroundColor: grey[200] } : {}}
              >
                <TableCell scope="row" className={classes.tableTd}>
                  {row.id}
                </TableCell>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.name}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {row.shortName}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {row.category}
                </TableCell>
                <TableCell className={classes.tableTd}>{row.company}</TableCell>
                <TableCell className={classes.tableTd}>
                  {row.orderWeight}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  <ColorfulButton onClick={() => getGameDetail(row.id)}>
                    Detail
                  </ColorfulButton>
                  <ColorfulButton onClick={() => dpSend(row.id)}>
                    DP SEND
                  </ColorfulButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ColorfulButton onClick={addNewGame}>Add</ColorfulButton>
      <div>
        <MyPagination
          count={count}
          page={page}
          pageRows={pageRows}
          handlePageChange={handlePageChange}
          handlePageRowsChange={handlePageRowsChange}
        />
      </div>
      <Dialog
        open={gameDetailOpen}
        onClose={handleGameDetailClose}
        className={classes.detailDialog}
      >
        <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>
          {gameDetailId === 0 ? "New Game" : "Game Detail"}
        </DialogTitle>
        <GameDetailDialog gameId={gameDetailId} />
      </Dialog>
      <Dialog
        open={dpSendOpen}
        onClose={handleDpSendClose}
        className={classes.detailDialog}
      >
        <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>
          Send Divepoints To users
        </DialogTitle>
        <DpSendDialog gameId={gameDetailId} />
      </Dialog>
    </div>
  );
};

export default GamePage;
