import React, { useState } from "react";
import { dialogStyles } from "./style";
import { ColorfulButton } from "../../../atoms/ColorfulButton";
import TextField from "@mui/material/TextField";
import {
    reqPostSendDivepoints,
} from "../../../../../../ts/api/game";
import { useRecoilState } from "recoil";
import { dpSendDialogState } from "src/ts/stores/slices/dialogOpen";
import { useToast } from "src/tsx/utils/toast";
import { isValidEthereumAddress, isPositiveInteger } from "src/ts/utils/string";
import {
    Button, FormControl, Table, Typography,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { listStyles } from "src/tsx/views/pages/game/style";
interface DpSendDialogProp {
    gameId: number;
}

const DpSendDialog = (prop: DpSendDialogProp) => {
    const classes = listStyles();
    const setDpSendDialogState = useRecoilState(dpSendDialogState)[1];
    const [userWallet, setUserWallet] = useState('');
    const [incAmount, setIncAmount] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [walletTransactionHistory, setWalletTransactionHistory] = useState([]);
    const { showToast } = useToast();

    const sendDivePoints = async () => {
        try {
            if (!isValidEthereumAddress(userWallet)) {
                showToast("Invalid Ethereum wallet address", "error");
                setResponseMessage("Error: Invalid Ethereum wallet address");
                return;
            }

            if (!isPositiveInteger(Number(incAmount))) {
                showToast("Amount must be a positive integer", "error");
                setResponseMessage("Error: Amount must be a positive integer");
                return;
            }

            const data = {
                gameId: prop.gameId,
                userWallet: userWallet,
                reason: 'Inc By Manager',
                amount: Number(incAmount),
            };

            const r = await reqPostSendDivepoints(data);
            const result = r.data;
            if (result.code === 200) {
                setUserWallet('');
                setIncAmount('');
                showToast("Send Successful", "success");
                setWalletTransactionHistory(result.data.walletTransactionsHistory);
                // setResponseMessage(`Send Successful\nUser Email: ${result.data.user.email} \nWallet: ${result.data.user.wallet_address} \nGame: ${result.data.game}\nAmount: ${result.data.amount}`);
                setResponseMessage("Send Successful")
                // setDpSendDialogState(false);
            }
        } catch (e) {
            showToast("Error: " + e.message, "error");
            setResponseMessage("Error: " + e.message);
        }
    };

    return (
        <div id="DpSendDialog" style={{ width: '500px', margin: '0 auto' }}>
            <FormControl style={{ width: '500px', margin: '0 auto' }}>
                <TextField
                    label="Game"
                    value={prop.gameId}
                    style={{ marginLeft: '15px', marginBottom: "15px", width: "100%" }}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <TextField
                    id="wallet"
                    label="Wallet"
                    value={userWallet}
                    onChange={(e) => setUserWallet(e.target.value)}
                    style={{ marginLeft: '15px', marginBottom: "15px", width: "100%" }}
                />
                <TextField
                    id="amount"
                    label="DivePoints Amount"
                    value={incAmount}
                    onChange={(e) => setIncAmount(e.target.value)}
                    style={{ marginLeft: '15px', marginBottom: "15px", width: "100%" }}
                />
                <ColorfulButton onClick={sendDivePoints} style={{ marginLeft: '15px', marginBottom: "15px", width: "100%" }}>
                    Confirm To Send
                </ColorfulButton>
                <Button onClick={() => setDpSendDialogState(false)} style={{ marginLeft: '15px', marginBottom: "15px", width: "100%" }}>
                    Close
                </Button>
                {responseMessage && (
                    <Typography variant="body1" style={{
                        marginLeft: '15px', marginBottom: "15px", whiteSpace: 'pre-wrap', display: 'block',
                        width: "100%", maxWidth: "300px", color: responseMessage.startsWith("Error") ? "red" : "green"
                    }}>
                        {responseMessage}
                    </Typography>
                )}

                {walletTransactionHistory && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableTh}>Id</TableCell>
                                    <TableCell className={classes.tableTh}>UserId</TableCell>
                                    <TableCell className={classes.tableTh}>Type</TableCell>
                                    <TableCell className={classes.tableTh}>Amount</TableCell>
                                    <TableCell className={classes.tableTh}>createdAt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {walletTransactionHistory.map((row) => (
                                    <TableRow
                                        key={row.id}
                                    >
                                        <TableCell scope="row" className={classes.tableTd}>
                                            {row.id}
                                        </TableCell>
                                        <TableCell scope="row" className={classes.tableTd}>
                                            {row.userId}
                                        </TableCell>
                                        <TableCell className={classes.tableTd}>
                                            {row.type === 1 ?'inc':'dec'}
                                        </TableCell>
                                        <TableCell className={classes.tableTd}>
                                            {row.amount} {row.unit}
                                        </TableCell>
                                        <TableCell className={classes.tableTd}>
                                            {row.createdAt}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </FormControl>
        </div>
    );
};

export default DpSendDialog;