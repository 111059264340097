import { BaseUserInfoDto } from "../entities/userInfo.entity";

export class ResponseUserInfoDto extends BaseUserInfoDto {
  id: number;

  constructor() {
    super();
    this.id = 0;
  }
}
