import React, { ChangeEvent } from "react";
import { TextField } from "@mui/material";
import { checkAllNumber } from "../../../../ts/serveice/input";

interface NumberInputProp {
  id?: string;
  label?: string;
  name?: string;
  variant: "standard" | "filled" | "outlined" | undefined;
  value: string | number | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const NumberInput = (prop: NumberInputProp) => {
  // 只能输入数字
  const handleInputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const checkResult = checkAllNumber(value);
    if (checkResult) {
      prop.onChange(e);
    }
  };
  return (
    <TextField
      id={prop.id}
      label={prop.label}
      name={prop.name}
      variant={prop.variant}
      value={prop.value}
      onChange={handleInputOnChange}
      style={{ marginLeft: 10 }}
      type="text"
    />
  );
};

export default NumberInput;
