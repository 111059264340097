import { GetReqDto } from '../../common';

export class GetTxsDto extends GetReqDto {
  userIdSearch: string;
  txIdSearch: string;
  fromSearch: string;
  toSearch: string;
  statusSearch: string;
  chainSearch: string;

  constructor(
    userIdSearch: string,
    txIdSearch: string,
    fromSearch: string,
    toSearch: string,
    statusSearch: string,
    chainSearch: string,
    page: number, pageRows: number) {
    super(page, pageRows);
    this.userIdSearch = userIdSearch;
    this.txIdSearch = txIdSearch;
    this.fromSearch = fromSearch;
    this.toSearch = toSearch;
    this.statusSearch = statusSearch;
    this.chainSearch = chainSearch;
  }
}

export interface TxsSelectSearch {
  uid?: number;
  txid?: string;
  from?: string;
  to?: string;
  status?: number;
  chain?: string;
}

export function getTxsSearchCondition(getTxsDto: GetTxsDto) {
  const search: TxsSelectSearch = {};

  const userIdSearch = +getTxsDto.userIdSearch;
  const txIdSearch = getTxsDto.txIdSearch;
  const fromSearch = getTxsDto.fromSearch;
  const toSearch = getTxsDto.toSearch;
  const statusSearch = +getTxsDto.statusSearch;
  const chainSearch = getTxsDto.chainSearch;

  if (userIdSearch) {
    search.uid = userIdSearch;
  }
  if (txIdSearch) {
    search.txid = txIdSearch;
  }
  if (fromSearch) {
    search.from = fromSearch;
  }
  if (toSearch) {
    search.to = toSearch;
  }
  if (statusSearch) {
    search.status = statusSearch;
  }
  if (chainSearch) {
    search.chain = chainSearch;
  }
  return search;
}
