import React, {ChangeEvent} from 'react';
import Pagination from "@mui/material/Pagination";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { FLEX_ALL_CENTER } from "../../../../style/globalStyle";

interface MyPaginationProps {
  count: number;
  page: number;
  pageRows: string;
  handlePageChange: (event: ChangeEvent<unknown>, value: number) => void;
  handlePageRowsChange: (event: SelectChangeEvent) => void;
}

const useMyPaginationStyles = makeStyles({
  root: {},
  paginationMain: {
    marginTop: "20px",
    ...FLEX_ALL_CENTER,
  },
  pageRowsSelect: {
    marginLeft: "20px",
  },
});
const MyPagination = (props: MyPaginationProps) => {
  const classes = useMyPaginationStyles();
  return (
    <div className={classes.paginationMain}>
      <Pagination
        count={props.count}
        variant="outlined"
        color="primary"
        page={props.page}
        onChange={props.handlePageChange}
      />
      <Select
        labelId="page-rows"
        id="page-rows-select"
        value={props.pageRows}
        label="Page Rows"
        onChange={props.handlePageRowsChange}
        className={classes.pageRowsSelect}
      >

        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
        <MenuItem value={200}>200</MenuItem>
      </Select>
    </div>
  );
};

export default MyPagination;
