import {
    Box,
    Button,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    Fade,
    Paper,
    Popper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Modal,
    SelectChangeEvent
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import NumberInput from "../../components/atoms/NumberInput";
import { listStyles } from "../game/style";
import { timeToStringFull } from "../../../../ts/extension/date";
import { changeDateToTokyoDate } from '../../../../ts/serveice/date';
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import { UpdateAdminDto } from "src/ts/interface/admin/interfaces/update-admin.interface";
import dayjs from "dayjs";
import { useEffect } from "react";
import MyPagination from "../../components/modules/myPagination";
import { GetAdminDto } from "src/ts/interface/admin/interfaces/get-admin.interface";
import { reqDeleteAdmin, reqGetAdmin, reqPostCreateAdmin } from "src/ts/api/admin";
import { CommonResponse } from "src/ts/interface/common.response";
import { getLocalDateTime } from "src/ts/utils/time";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const AdminPage = () => {
    const classes = listStyles();
    const [page, setPage] = useState(1);
    // 分页的页数总数
    const [count, setCount] = useState<number>(0);
    // 分页每一页的页数
    const [pageRows, setPageRows] = useState<string>("200");

    const [adminList, setAdminList] = useState<UpdateAdminDto[]>([]);
    const [editAdminUid, setEditAdminUid] = useState(0);
    const [editAdminUserName, setEditAdminUserName] = useState('');
    const [editAdminPassword, setEditAdminPassword] = useState('');

    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const deleteAdmin = async (id: number) => {
        const r = await reqDeleteAdmin({ id: id });
        console.log(r);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            getAdmin();
        }
    }

    const editAdmin = (admin: UpdateAdminDto) => {
        setEditAdminPassword('');
        if (admin !== null) {
            setEditAdminUid(admin.id);
            setEditAdminUserName(admin.username);
        } else {
            setEditAdminUid(0);
            setEditAdminUserName('');
        }

        console.log("admin", admin);
        setOpen(true);
    }

    const saveEditAdmin = async () => {
        const data = {
            id: editAdminUid,
            username: editAdminUserName,
            password: editAdminPassword
        };
        const result = await reqPostCreateAdmin(data);
        console.log("result", result);

        setOpen(false);
        getAdmin();
    }

    const getAdmin = async () => {
        const getUserInfoData = new GetAdminDto(
            page,
            parseInt(pageRows),
        );
        const r = await reqGetAdmin(getUserInfoData);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            setAdminList(result.data);
        }
        const pageCount = Math.ceil(result.count / parseInt(pageRows));
        setCount(pageCount);
    }

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getAdmin();
    }, [page, pageRows])

    return (
        <div id="Admin" className={classes.root}>
            <div className={classes.searchInputMain}>
                <Button
                    variant="contained"
                    className={classes.searchInputButton}
                    onClick={() => editAdmin(null)}
                >
                    Create admin
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>Id</TableCell>
                            <TableCell className={classes.tableTh}>User Name</TableCell>
                            <TableCell className={classes.tableTh}>Last Login Time</TableCell>
                            <TableCell className={classes.tableTh}>Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {adminList.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.id}
                                </TableCell>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.username}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getLocalDateTime(new Date(row.updateTime * 1000).toString())}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <ColorfulButton onClick={() => editAdmin(row)}>
                                        Edit
                                    </ColorfulButton>
                                    <ColorfulButton style={{ background: 'red' }} onClick={() => deleteAdmin(row.id)}>
                                        Delete
                                    </ColorfulButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <h2 id="parent-modal-title">{editAdminUid === 0 ? ('Add') : ('Edit')} Admin</h2>
                    <p id="parent-modal-description">
                        If you need to change the password, please fill in the password, otherwise leave it blank.
                    </p>
                    <TextField
                        disabled
                        margin="normal"
                        required
                        fullWidth
                        name="AdminId"
                        label="Uid"
                        type="text"
                        id="editAdminId"
                        autoComplete="current-username"
                        value={editAdminUid}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="username"
                        label="Username"
                        type="text"
                        id="editAdminUserName"
                        value={editAdminUserName}
                        onChange={(e) => { setEditAdminUserName(e.target.value) }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="editAdminPassword"
                        value={editAdminPassword}
                        onChange={(e) => { setEditAdminPassword(e.target.value) }}
                    />

                    <ColorfulButton onClick={() => { saveEditAdmin() }}>
                        Save
                    </ColorfulButton>
                    <ColorfulButton onClick={() => { handleClose() }}>
                        Cancel
                    </ColorfulButton>
                </Box>
            </Modal>

            <div>
                <MyPagination
                    count={count}
                    page={page}
                    pageRows={pageRows}
                    handlePageChange={handlePageChange}
                    handlePageRowsChange={handlePageRowsChange}
                />
            </div>
        </div>
    )
}
export default AdminPage;