// 获取nftItem list
import { GetNftItemDto } from 'src/ts/interface/nft/interfaces/get-nft.interface';
import ajaxRequest, { requestType } from '../../extension/ajax/request';

export const reqGetNftItem = async (data: GetNftItemDto) =>
  await ajaxRequest('/nft/list', data, requestType.GET);

// 获取nftItem detail
export const reqGetNftItemDetail = async (data: any) =>
  await ajaxRequest(`/nft/${data.nftItemId}`, data, requestType.GET);

// // 作成gameItem
// export const reqPostGameItem = async (data: any) =>
//   await ajaxRequest('/gameItem', data, requestType.POST);

// // 删除gameItem
// export const reqDeleteGameItem = async (data: any) =>
//   await ajaxRequest(`/gameItem/${data.gameItemId}`, data, requestType.DELETE);

// // 更新gameItem
// export const reqUpdateGameItem = async (data: any) =>
//   await ajaxRequest(`/gameItem/${data.id}`, data, requestType.PATCH);

// export const reqGetGameItemSetState = async (data: any) =>
//   await ajaxRequest(`/gameItem/setState/${data.gameItemId}/${data.state}`, data, requestType.GET);