import * as React from "react";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { reqPostLogin } from "../../../../ts/api/login";
import { useHistory } from "react-router-dom";
import { USER_INFO } from "../../../../ts/router/url";

const theme = createTheme();

export default function LoginPage() {
  const history = useHistory();
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (rememberMe) {
      localStorage.setItem("kokoConsolePassword", password);
    }
    const passwordData = {
      username: username,
      password: password,
    };

    try {
      console.log(passwordData);
      const r = await reqPostLogin(passwordData);
      if (r.data) {
        console.log("r/data", r.data);
        const token = r.data.data;
        console.log("token", token);
        localStorage.setItem("kokoConsoleToken", token);
        history.push(USER_INFO);
      }
    } catch (e) {
      console.log(e);
      alert("Password incorrect");
    }
  };
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
    localStorage.setItem("kokoConsoleRememberMe", String(e.target.checked));
  };

  useEffect(() => {
    const kokoConsoleRememberMe = localStorage.getItem("kokoConsoleRememberMe");
    const kokoPassword = localStorage.getItem("kokoConsolePassword");
    if (kokoPassword && kokoConsoleRememberMe === "true") {
      setPassword(kokoPassword);
      setRememberMe(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login In
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label="Username"
              type="text"
              id="username"
              autoComplete="current-username"
              value={username}
              onChange={(e)=>{setUsername(e.target.value)}}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                  color="primary"
                />
              }
              label="Remember Me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}