import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { dialogStyles } from "../game/gameDetailDialog/style";
import { ColorfulButton } from "../../atoms/ColorfulButton";
import { timeToStringFull } from "../../../../../ts/extension/date";
import { useRecoilState } from 'recoil';
import { txsDialogState } from '../../../../../ts/stores/slices/dialogOpen';
import { changeDateToTokyoDate } from '../../../../../ts/serveice/date';
import { getBlockchainExplorerTransactionLink, getBlockchainExplorerWalletLink } from "src/ts/config/Web3AuthConfig";
interface TxsDetailDialogProp {
  id: number;
  txid: string;
  uid: number;
  from: string;
  to: string;
  status: number;
  created: Date;
  chain: string;
}

function formatStatus(status: number) {
  if (status === 100) return 'Finish';
  if (status === 0) return 'Processing';
  if (status === -1) return 'Cancel';
  return 'Unknown';
}

const TxsDetailDialog = (prop: TxsDetailDialogProp) => {
  const classes = dialogStyles();
  const setTxsDialogState = useRecoilState(txsDialogState)[1];


  const closeDialog = () => {
    setTxsDialogState(false);
  };
  return (
    <div id="TxsDetailDialog">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Hash
              </TableCell>
              <TableCell className={classes.tableTd}>
                <a target="_blank" href={getBlockchainExplorerTransactionLink(prop.chain, prop.txid)}>{prop.txid}</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                UserId
              </TableCell>
              <TableCell className={classes.tableTd}>
                {prop.uid}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                From
              </TableCell>
              <TableCell className={classes.tableTd}>
                <a target="_blank" href={getBlockchainExplorerWalletLink(prop.chain, prop.from)}>{prop.from}</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>

                To
              </TableCell>
              <TableCell className={classes.tableTd}>
                <a target="_blank" href={getBlockchainExplorerWalletLink(prop.chain, prop.to)}>{prop.to}</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Status
              </TableCell>
              <TableCell className={classes.tableTd}>
                {formatStatus(prop.status)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Chain
              </TableCell>
              <TableCell className={classes.tableTd}>
                {prop.chain}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Create Time
              </TableCell>
              <TableCell className={classes.tableTd}>
                {timeToStringFull(changeDateToTokyoDate(prop.created))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.dialogButtonsMain}>
        <ColorfulButton onClick={closeDialog}>OK</ColorfulButton>
      </div>
    </div>
  );
};

export default TxsDetailDialog;
