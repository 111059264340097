import dayjs from "dayjs";

export const timestampToString = (timestamp: number) => {
  const date = dayjs(timestamp);
  return date.format("YYYY-MM-DD");
};

export const timeToStringFull = (time: number | string | Date| null) => {
  if (time) {
    const date = dayjs(time);
    return date.format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "";
  }
};

export const timeToHourMinute  = (time: number | string | Date) => {
  if (time) {
    const date = dayjs(time);
    return date.format("HH:mm");
  } else {
    return "";
  }
};