export class BaseUserInfoDto {
  create_time: Date;
  email: string;
  password?: string;
  email_verified: number;
  locale?: string;
  modify_time: Date;
  picture: string;
  profile_photo: string;
  subscriber: string;
  user_name: string;
  status: number;
  eth_private_key: string;
  idtoken: string;
  wallet_address: string;
  refferralCode: string;
  refferralUid: number;
  registerIp?: string;

  constructor() {
      this.email = '';
      this.password = '';
      this.email_verified = -1;
      this.locale = '';
      this.picture = '1';
      this.profile_photo = '';
      this.subscriber = '';
      this.user_name = '';
      this.eth_private_key = '';
      this.modify_time = new Date();
      this.create_time = new Date();
      this.status = 0;
      this.idtoken = '';
      this.wallet_address = '';
      this.refferralUid = 0;
      this.refferralCode = '';
      this.registerIp = '';
  }
}
