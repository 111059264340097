// TableComponent.js
import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from "@mui/material";

// 检查值是否为图片链接的正则表达式
const isImageUrl = (url) => {
    return /\.(jpeg|jpg|gif|png)$/.test(url);
}

// 检查值是否为URL链接的正则表达式
const isUrl = (url) => {
    const pattern = new RegExp('^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(url);
}

const TableComponent = ({ objArray, classes, onEdit, onDelete, showEdit, showDelete }) => {
    if (!objArray || objArray.length === 0) {
        return <div>No data available</div>;
    }

    // Get the keys from the first object in the array for the table headers
    const headers = Object.keys(objArray[0]);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell key={header} className={classes.tableTh}>
                                {header}
                            </TableCell>
                        ))}
                        {(showEdit || showDelete) && (
                            <TableCell className={classes.tableTh}>Options</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {objArray.map((row, index) => (
                        <TableRow key={index}>
                            {headers.map((header) => (
                                <TableCell key={header} className={classes.tableTd}>
                                    {isImageUrl(row[header]) ? (
                                        <img src={row[header]} alt={header} style={{ width: '100px', height: 'auto' }} />
                                    ) : isUrl(row[header]) ? (
                                        <a href={row[header]} target="_blank" rel="noopener noreferrer">LINK</a>
                                    ) : (
                                        row[header]
                                    )}
                                </TableCell>
                            ))}
                            {(showEdit || showDelete) && (
                                <TableCell className={classes.tableTd}>
                                    {showEdit && onEdit && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => onEdit(row)}
                                            style={{ marginRight: '8px' }}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                    {showDelete && onDelete && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => onDelete(row)}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

// 设置默认属性
TableComponent.defaultProps = {
    showEdit: false,
    showDelete: false,
};

export default TableComponent;