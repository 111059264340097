import { ChainEnum, PriceTypeEnum } from "../../constants";

export const chains = {
    arbitrum: {
        chain: "0xa4b1",
        chainId: 42161,
        displayName: "Arbitrum One Mainnet",
        chainName: ChainEnum.arbitrum,
        ticker: "ETH",
        tickerName: "ETH",
        blockExplorer: "https://arbiscan.io/",
        rpc: "https://arb1.arbitrum.io/rpc",
        logo: "/chain/arbitrum.png",
        supportToken: [PriceTypeEnum.usdc, PriceTypeEnum.usdt, PriceTypeEnum.divepoint]
    },
    arbitrumNova: {
        chain: "0xa4ba",
        chainId: 42170,
        displayName: "Arbitrum Nova",
        chainName: ChainEnum.arbitrumNova,
        ticker: "ETH",
        tickerName: "ETH",
        blockExplorer: "https://nova-explorer.arbitrum.io/",
        rpc: "https://open-platform.nodereal.io/924fb8b3c76649f38a9c108db7ecb1a3/arbitrum/",
        logo: "/chain/arbitrumNova.svg",
        supportToken: [PriceTypeEnum.usdc, PriceTypeEnum.divepoint]
    },
    imx: {
        chain: "0x343b",
        chainId: 13371,
        displayName: "Immutable",
        chainName: ChainEnum.imx,
        ticker: "IMX",
        tickerName: "IMX",
        blockExplorer: "https://explorer.immutable.com",
        rpc: "https://rpc.immutable.com/",
        logo: "/chain/imx.png",
        supportToken: [PriceTypeEnum.usdc, PriceTypeEnum.divepoint]
    },
    avac: {
        chain: "0xa86a",
        chainId: 43114,
        displayName: "Avalanche Network C-Chain",
        chainName: ChainEnum.avac,
        ticker: "AVAX",
        tickerName: "AVAX",
        blockExplorer: "https://snowtrace.io/",
        rpc: "https://open-platform.nodereal.io/924fb8b3c76649f38a9c108db7ecb1a3/avalanche-c/ext/bc/C/rpc",
        logo: "/chain/avac.svg",
        supportToken: [PriceTypeEnum.usdc, PriceTypeEnum.usdt, PriceTypeEnum.divepoint]
    },
    polygon: {
        chain: "0x89",
        chainId: 137,
        displayName: "Polygon",
        chainName: ChainEnum.polygon,
        ticker: "MATIC",
        tickerName: "MATIC",
        blockExplorer: "https://polygonscan.com",
        rpc: "https://polygon-mainnet.nodereal.io/v1/924fb8b3c76649f38a9c108db7ecb1a3",
        logo: "/chain/polygon.png",
        supportToken: [PriceTypeEnum.usdc, PriceTypeEnum.usdt, PriceTypeEnum.divepoint]
    },
    mumbai: {
        chain: "0x13881",
        chainId: 8001,
        displayName: "Mumbai",
        chainName: ChainEnum.mumbai,
        ticker: "MATIC",
        tickerName: "MATIC",
        blockExplorer: "https://mumbai.polygonscan.com",
        rpc: "https://rpc-mumbai.maticvigil.com/",
        logo: "/chain/polygon.png",
        supportToken: [PriceTypeEnum.usdc, PriceTypeEnum.usdt, PriceTypeEnum.divepoint]
    }
}

export const defaultChain = "polygon";


export interface ChainData {
    [key: string]: {
        chain: string;
        chainId: number;
        displayName: string;
        chainName: string;
        ticker: string;
        tickerName: string;
        blockExplorer: string;
        rpc: string;
        logo: string;
        supportToken: string[];
    };
}