import ajaxRequest, { requestType } from "../../extension/ajax/request";
import {GetUserDetailDto, GetUserInfoDto} from '../../interface/userInfo/interfaces/get-userInfo.interface';

// 获取userInfo list
export const reqUserInfo = async (data: GetUserInfoDto) =>
  await ajaxRequest("/userInfo/list", data, requestType.GET);

// 获取userInfo detail
export const reqGetUserInfoDetail = async (data: GetUserDetailDto) =>
  await ajaxRequest(
    `/userInfo/${data.userInfoId}`,
    data,
    requestType.GET
  );
