import {
    Box,
    Button,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    Fade,
    Paper,
    Popper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Modal,
    SelectChangeEvent,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import NumberInput from "../../components/atoms/NumberInput";
import { listStyles } from "../game/style";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import dayjs from "dayjs";
import { useEffect } from "react";
import { UpdateRelayCall } from "src/ts/interface/relayCall/interfaces/update-relay-call.interface";
import MyPagination from "../../components/modules/myPagination";
import { GetRelayCallDto } from "src/ts/interface/relayCall/interfaces/get-relayCall.interface";
import { reqDeleteRelayCall, reqGetRelayCall } from "src/ts/api/relayCall";
import { getBlockchainExplorerWalletLink, getChainName, getSupportChains } from "src/ts/config/Web3AuthConfig";
import { formatHash, formatWallet } from "src/ts/utils/format";
import { useRecoilState } from "recoil";
import { relayCallDialogState } from "src/ts/stores/slices/dialogOpen";
import { TEXT_ALIGN_CENTER } from "src/tsx/style/globalStyle";
import RelayCallDetailDialog from "../../components/modules/relayCall/RelayCallDialog";
import { CommonResponse } from "src/ts/interface/common.response";
import { getLocalDateTime } from "src/ts/utils/time";

const RelayCall = () => {
    const [page, setPage] = useState(1);
    // 分页的页数总数
    const [count, setCount] = useState<number>(0);
    // 分页每一页的页数
    const [pageRows, setPageRows] = useState<string>("200");

    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const [relaycallList, setRelaycallList] = useState<UpdateRelayCall[]>([]);
    const classes = listStyles();

    const [transactionHashSearch, setTransactionHashSearch] = useState('');
    const [taskStateSearch, setTaskStateSearch] = useState('');
    const [userSearch, setUserSearch] = useState('');
    const [targetSearch, setTargetSearch] = useState('');
    const [chainIdSearch, setChainIdSearch] = useState('');
    const [chains, setChains] = useState([]);

    const [dialogChainId, setDialogChainId] = useState(0);
    const [dialogTarget, setDialogTarget] = useState('');
    const [dialogRelayData, setDialogRelayData] = useState('');
    const [dialogUser, setDialogUser] = useState('');
    const [dialogTaskState, setDialogTaskState] = useState('');
    const [dialogTransactionHash, setDialogTransactionHash] = useState('');
    const [dialogCtime, setDialogCtime] = useState(new Date());

    const getRelayCallDetail = (relayCall: UpdateRelayCall) => {
        setDialogChainId(relayCall.chainId);
        setDialogTarget(relayCall.target);
        setDialogRelayData(relayCall.relayData);
        setDialogUser(relayCall.user);
        setDialogTaskState(relayCall.taskState);
        setDialogTransactionHash(relayCall.transactionHash);
        setDialogCtime(relayCall.ctime);
        setRelayCallDetailOpen(true);
    }

    const supportChains = () => {
        const chains = getSupportChains();
        console.log("chains", chains);
        setChains(chains);
    }

    const deleteRelayCall = async (id: number) => {
        const r = await reqDeleteRelayCall({ id: id });
        console.log(r);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            getRelayCall();
        }
    }

    const getRelayCall = async () => {
        const getRelayCallData = new GetRelayCallDto(
            chainIdSearch,
            targetSearch,
            userSearch,
            taskStateSearch,
            transactionHashSearch,
            page,
            parseInt(pageRows),
        );
        const r = await reqGetRelayCall(getRelayCallData);

        const result = r.data;
        if (result.code === 200) {
            setRelaycallList(result.data);
            const pageCount = Math.ceil(result.count / parseInt(pageRows));
            setCount(pageCount);
        }
    }

    const [relayCallDetailOpen, setRelayCallDetailOpen] = useRecoilState(relayCallDialogState);

    const handleRelayCallDetailClose = () => {
        setRelayCallDetailOpen(false);
    };

    const handleSearchButtonClick = () => {
        setPage(1);
        getRelayCall();
    }

    useEffect(() => {
        getRelayCall();
    }, [page, pageRows]);

    useEffect(() => {
        supportChains();
    }, [])

    return (
        <div id="RelayCall" className={classes.root}>
            <div className={classes.searchInputMain}>
                <FormControl variant="standard" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="Status-Label">Chain</InputLabel>
                    <Select
                        labelId="ChainId"
                        id="ChainId"
                        value={chainIdSearch}
                        onChange={(e) => {
                            setChainIdSearch(e.target.value);
                        }}
                        label="Chain"
                    >
                        <MenuItem value="" key="">
                            <em>None</em>
                        </MenuItem>
                        {chains.map((row) => (
                            <MenuItem key={row.chainId} value={row.chainId}><em>{row.displayName}</em></MenuItem>
                        ))};
                    </Select>
                </FormControl>
                <NumberInput
                    id="Target"
                    label="Target"
                    variant="standard"
                    value={targetSearch}
                    onChange={(e) => setTargetSearch(e.target.value)}
                />
                <TextField
                    id="User"
                    label="User"
                    variant="standard"
                    value={userSearch}
                    onChange={(e) => setUserSearch(e.target.value)}
                    style={{ marginLeft: 10 }}
                />
                <TextField
                    id="Hash"
                    label="Hash"
                    variant="standard"
                    value={transactionHashSearch}
                    onChange={(e) => setTransactionHashSearch(e.target.value)}
                    style={{ marginLeft: 10 }}
                />
                <FormControl variant="standard" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="PriceType-Label">Status</InputLabel>
                    <Select
                        labelId="Status"
                        id="Status"
                        value={taskStateSearch}
                        onChange={(e) => {
                            setTaskStateSearch(e.target.value);
                        }}
                        label="Status"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'ExecSuccess'}>ExecSuccess</MenuItem>
                        <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                    </Select>
                </FormControl>

                <Button
                    variant="contained"
                    className={classes.searchInputButton}
                    onClick={handleSearchButtonClick}
                >
                    Search
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>Index</TableCell>
                            <TableCell className={classes.tableTh}>Chain</TableCell>
                            <TableCell className={classes.tableTh}>Target</TableCell>
                            <TableCell className={classes.tableTh}>User</TableCell>
                            <TableCell className="left">Status</TableCell>
                            <TableCell className="left">Hash</TableCell>
                            <TableCell className={classes.tableTh}>Ctime</TableCell>
                            <TableCell className={classes.tableTh}>Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {relaycallList.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.id}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getChainName(row.chainId)}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <a target="_blank" href={getBlockchainExplorerWalletLink(getChainName(row.chainId), row.target)}>{formatWallet(row.target)}</a>
                                </TableCell>

                                <TableCell className={classes.tableTd}>
                                    <a target="_blank" href={getBlockchainExplorerWalletLink(getChainName(row.chainId), row.user)}>{formatWallet(row.user)}</a>
                                </TableCell>
                                <TableCell className="left">
                                    <span className="text-primary">{row.taskState}</span>
                                </TableCell>
                                <TableCell className="left long-text-col">
                                    <textarea>
                                        {row.transactionHash}
                                    </textarea>
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getLocalDateTime(row.ctime.toString())}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <ColorfulButton onClick={() => getRelayCallDetail(row)}>
                                        Detail
                                    </ColorfulButton>
                                    <ColorfulButton style={{ background: 'red' }} onClick={() => deleteRelayCall(row.id)}>
                                        delete
                                    </ColorfulButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <div>
                <MyPagination
                    count={count}
                    page={page}
                    pageRows={pageRows}
                    handlePageChange={handlePageChange}
                    handlePageRowsChange={handlePageRowsChange}
                />
            </div>

            <Dialog
                open={relayCallDetailOpen}
                onClose={handleRelayCallDetailClose}
                className={classes.detailDialog}
            >
                <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>Transaction Detail</DialogTitle>
                <RelayCallDetailDialog
                    chainId={dialogChainId}
                    target={dialogTarget}
                    relayData={dialogRelayData}
                    user={dialogUser}
                    taskState={dialogTaskState}
                    transactionHash={dialogTransactionHash}
                    ctime={dialogCtime}
                />
            </Dialog>
        </div>
    )
}
export default RelayCall;