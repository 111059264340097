import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { dialogStyles } from "../game/gameDetailDialog/style";
import { ColorfulButton } from "../../atoms/ColorfulButton";
import { timeToStringFull } from "../../../../../ts/extension/date";
import { useRecoilState } from 'recoil';
import { offerDialogState } from '../../../../../ts/stores/slices/dialogOpen';
import { changeDateToTokyoDate } from '../../../../../ts/serveice/date';
import { formatPrice } from "src/ts/utils/format";
import dayjs from "dayjs";
interface OfferDetailDialogProp {
    txid: string;
    uid: number;
    buyer: string;
    tokenid: number;
    price: string;
    priceType: string;
    status: number;
    created: Date;
    expireTime: number;
}

function formatStatus(status: number) {
    if (status === 100) return 'Finish';
    if (status === 0) return 'Processing';
    if (status === 101) return 'Cancel';
    return 'Unknown';
}

const OfferDetailDialog = (prop: OfferDetailDialogProp) => {
    const classes = dialogStyles();
    const setOfferDialogState = useRecoilState(offerDialogState)[1];


    const closeDialog = () => {
        setOfferDialogState(false);
    };
    return (
        <div id="OfferDetailDialog">
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Hash
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.txid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                UserId
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.uid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Buyer
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.buyer}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                TokenId
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.tokenid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Price
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {formatPrice(prop.price, prop.priceType)} {prop.priceType}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Status
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {formatStatus(prop.status)}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Create Time
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {timeToStringFull(changeDateToTokyoDate(prop.created))}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Expire Time
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {dayjs(prop.expireTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.dialogButtonsMain}>
                <ColorfulButton onClick={closeDialog}>OK</ColorfulButton>
            </div>
        </div>
    );
};

export default OfferDetailDialog;
