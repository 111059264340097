import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import MyPagination from "../../components/modules/myPagination";
import GameItemDetailDialog from "../../components/modules/gameItem/gameItemDetailDialog";
import { GetGameItemDto } from "../../../../ts/interface/gameItem/interfaces/get-gameItem.interface";
import { GameItemResponseEntity } from "../../../../ts/interface/gameItem/entities/gameItem-api.entity";
import { ResponseGameItemDto } from "../../../../ts/interface/gameItem/interfaces/response-gameItem.interface";
import { reqGetGameItem, reqGetGameItemSetState } from "../../../../ts/api/gameItem";
import { useRecoilState } from "recoil";
import { listStyles } from "../game/style";
import { timeToStringFull } from "../../../../ts/extension/date";
import { TEXT_ALIGN_CENTER } from "../../../style/globalStyle";
import { gameItemDialogState } from "src/ts/stores/slices/dialogOpen";
import { changeDateToTokyoDate } from "../../../../ts/serveice/date";
import { socket } from '../../../../ts/extension/ajax/request';
import { getLocalDateTime } from "src/ts/utils/time";

/*  添加 自动生成 game item
    修改,一览,详细
 */
const GameItemPage = () => {
  const classes = listStyles();

  const [page, setPage] = useState(1);
  // 分页的页数总数
  const [count, setCount] = useState<number>(0);
  // 分页每一页的页数
  const [pageRows, setPageRows] = useState<string>("200");
  const [gameItemList, setGameItemList] = useState<ResponseGameItemDto[]>([]);
  const [gameItemDetailOpen, setGameItemDetailOpen] =
    useRecoilState(gameItemDialogState);
  const [gameItemDetailId, setGameItemDetailId] = useState<number>(1);

  const handlePageRowsChange = (event: SelectChangeEvent) => {
    setPageRows(event.target.value as string);
    setPage(1);
  };
  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    (async () => {
      await getGameItemList();
    })();
  }, [page, pageRows]);
  // 当每页大小改变的时候，重新计算页数
  useEffect(() => {
    const pageCount = Math.ceil(count / parseInt(pageRows));
    setCount(pageCount);
    setPage(1);
  }, [pageRows, setCount]);

  const getGameItemList = async () => {
    const getGameItemData = new GetGameItemDto(page, parseInt(pageRows));
    const r = await reqGetGameItem(getGameItemData);
    const result: GameItemResponseEntity = r.data;
    if (result.code === 200) {
      setGameItemList(result.data);
    }
    const pageCount = Math.ceil(result.count / parseInt(pageRows));
    setCount(pageCount);
  };

  const getGameItemDetail = (id: number) => {
    setGameItemDetailId(id);
    setGameItemDetailOpen(true);
  };

  const addNewGameItem = () => {
    setGameItemDetailId(0);
    setGameItemDetailOpen(true);
  };
  const handleGameItemDetailClose = () => {
    setGameItemDetailOpen(false);
  };
  const setState = async (id: number, state: number) => {
    const r = await reqGetGameItemSetState({ gameItemId: id, state: state });
    const result = r.data;
    if (result.code === 200) {
      getGameItemList();
    }
  }

  useEffect(() => {
    // 当GameItem有更新或者添加时，刷新
    socket.on("getGameItemListToClient", () => {
      getGameItemList();
    });
  }, []);

  return (
    <div id="GameItem" className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTh}>Id</TableCell>
              <TableCell className={classes.tableTh}>gameId(Name)</TableCell>
              <TableCell className={classes.tableTh}>Name</TableCell>
              <TableCell className={classes.tableTh}>
                Picture Url(100*100){" "}
              </TableCell>
              <TableCell className={classes.tableTh}>CreateTime</TableCell>
              <TableCell className={classes.tableTh}>Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameItemList.map((row) => (
              <TableRow key={row.id}>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.id}
                </TableCell>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.gameId} ({row.gameName})
                </TableCell>
                <TableCell className={classes.tableTd}>{row.name}</TableCell>
                <TableCell className={classes.tableTd}>
                  <img
                    src={row.pictureUrl}
                    alt={row.pictureUrl}
                    className={classes.screenshot}
                  />
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {getLocalDateTime(row.createTime.toString())}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  <ColorfulButton onClick={() => getGameItemDetail(row.id)}>
                    Detail
                  </ColorfulButton>
                  <ColorfulButton style={{ background: 'red' }} onClick={() => setState(row.id, -1)}>
                    Delete
                  </ColorfulButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <ColorfulButton onClick={addNewGameItem}>Add</ColorfulButton> */}
      <div>
        <MyPagination
          count={count}
          page={page}
          pageRows={pageRows}
          handlePageChange={handlePageChange}
          handlePageRowsChange={handlePageRowsChange}
        />
      </div>
      <Dialog
        open={gameItemDetailOpen}
        onClose={handleGameItemDetailClose}
        className={classes.detailDialog}
      >
        <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>
          {gameItemDetailId === 0 ? "New GameItem" : "GameItem Detail"}
        </DialogTitle>
        <GameItemDetailDialog gameItemId={gameItemDetailId} />
      </Dialog>
    </div>
  );
};

export default GameItemPage;
