import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { routers } from "./ts/router";
import { initializeApp } from "firebase/app"
import { ToastProvider } from "./tsx/utils/toast";
const firebaseConfig = {
  apiKey: "AIzaSyCpf1EY8lW1_J29sTZB9QaxJ7qBQw3938Y",
  authDomain: "kokonats-dev-42730.firebaseapp.com",
  databaseURL: "https://kokonats-dev-42730-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "kokonats-dev-42730",
  storageBucket: "kokonats-dev-42730.appspot.com",
  messagingSenderId: "124310744417",
  appId: "1:124310744417:web:3abfe2eeb30ff3454a2920",
  measurementId: "G-G2EG4VK2W2"
};
class App extends Component {
  componentWillMount() {
    // Initialize Firebase
    // const app = initializeApp(firebaseConfig);
  }

  render() {
    return (
      <div id="App">
        <ToastProvider>
          <HashRouter>
            <Switch>
              {routers.map((router, index) => (
                <Route
                  path={router.path}
                  exact={router.exact}
                  component={router.render}
                  key={index}
                />
              ))}
            </Switch>
          </HashRouter>
        </ToastProvider>
      </div>
    );
  }
}

export default App;
