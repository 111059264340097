import { atom } from "recoil";

export const gameDialogState = atom<boolean>({
  key: "gameDialogState",
  default: false,
});

export const dpSendDialogState = atom<boolean>({
  key: "dpSendDialogState",
  default: false,
});

export const tournamentDialogState = atom<boolean>({
  key: "tournamentDialogState",
  default: false,
});

export const tournamentClassDialogState = atom<boolean>({
  key: "tournamentClassDialogState",
  default: false,
});

export const tournamentPlayDialogState = atom<boolean>({
  key: "tournamentPlayDialogState",
  default: false,
});

export const userPayoutDialogState = atom<boolean>({
  key: "userPayoutDialogState",
  default: false,
});

export const userInfoDialogState = atom<boolean>({
  key: "userInfoDialogState",
  default: false,
});

export const txsDialogState = atom<boolean>({
  key: "txsDialogState",
  default: false,
});

export const offerDialogState = atom<boolean>({
  key: "offerDialogState",
  default: false,
});

export const relayCallDialogState = atom<boolean>({
  key: "relayCallDialogState",
  default: false,
});

export const purchaseRecordDialogState = atom<boolean>({
  key: "purchaseRecordDialogState",
  default: false,
});

export const matchDialogState = atom<boolean>({
  key: "matchDialogState",
  default: false,
});

export const matchPlayDialogState = atom<boolean>({
  key: "matchPlayDialogState",
  default: false,
});

export const matchClassDialogState = atom<boolean>({
  key: "matchClassDialogState",
  default: false,
});

export const gameItemDialogState = atom<boolean>({
  key: "gameItemDialogState",
  default: false,
});


export const withdrawInfoDialogState = atom<boolean>({
  key: "withdrawInfoDialogState",
  default: false,
});

export const energyBalanceDialogState = atom<boolean>({
  key: "energyBalanceDialogState",
  default: false,
});

export const energyBillDialogState = atom<boolean>({
  key: "energyBillDialogState",
  default: false,
});

export const opTwitterAccountDialogState = atom<boolean>({
  key: "opTwitterAccountDialogState",
  default: false,
});

export const opTwitterDmArticleDialogState = atom<boolean>({
  key: "opTwitterDmArticleDialogState",
  default: false,
});

export const opTargetTwitterDialogState = atom<boolean>({
  key: "opTargetTwitterDialogState",
  default: false,
});

// 上传withdraw文件弹窗
export const withdrawInfoNewState = atom<boolean>({
  key: "withdrawInfoNewState",
  default: false,
});

// 上传文件时，如果成功
export const withdrawInfoUploadSuccessState = atom<boolean>({
  key: "withdrawInfoUploadSuccessState",
  default: false,
});

// 上传文件时，如果失败
export const withdrawInfoUploadErrorState = atom<boolean>({
  key: "withdrawInfoUploadErrorState",
  default: false,
});

// 上传opTargetTwitter文件弹窗
export const opTargetTwitterNewState = atom<boolean>({
  key: "opTargetTwitterNewState",
  default: false,
});

// 上传opTargetTwitter文件时，如果成功
export const opTargetTwitterUploadSuccessState = atom<boolean>({
  key: "opTargetTwitterUploadSuccessState",
  default: false,
});

// 上传opTargetTwitter文件时，如果失败
export const opTargetTwitterUploadErrorState = atom<boolean>({
  key: "opTargetTwitterUploadErrorState",
  default: false,
});

export const nftItemDialogState = atom<boolean>({
  key: "nftItemDialogState",
  default: false,
});