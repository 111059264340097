import { GetReqDto } from '../../common';

export class GetOfferDto extends GetReqDto {
  userIdSearch: string;
  txIdSearch: string;
  buyerSearch: string;
  priceTypeSearch: string;
  statusSearch: string;
  tokenIdSearch: string;

  constructor(
    userIdSearch: string,
    txIdSearch: string,
    buyerSearch: string,
    priceTypeSearch: string,
    statusSearch: string,
    tokenIdSearch: string,
    page: number, pageRows: number) {
    super(page, pageRows);
    this.userIdSearch = userIdSearch;
    this.txIdSearch = txIdSearch;
    this.buyerSearch = buyerSearch;
    this.priceTypeSearch = priceTypeSearch;
    this.statusSearch = statusSearch;
    this.tokenIdSearch = tokenIdSearch;
  }
}

export interface OfferSelectSearch {
  uid?: number;
  txid?: string;
  buyer?: string;
  tokenid?: number;
  status?: number;
  priceType?: string;
}

export function getOfferSearchCondition(getOfferDto: GetOfferDto) {
  const search: OfferSelectSearch = {};

  const userIdSearch = +getOfferDto.userIdSearch;
  const txIdSearch = getOfferDto.txIdSearch;
  const buyerSearch = getOfferDto.buyerSearch;
  const priceTypeSearch = getOfferDto.priceTypeSearch;
  const statusSearch = +getOfferDto.statusSearch;
  const tokenIdSearch = +getOfferDto.tokenIdSearch;

  if (userIdSearch) {
    search.uid = userIdSearch;
  }
  if (txIdSearch) {
    search.txid = txIdSearch;
  }
  if (buyerSearch) {
    search.buyer = buyerSearch;
  }
  if (priceTypeSearch) {
    search.priceType = priceTypeSearch;
  }
  if (statusSearch) {
    search.status = statusSearch;
  }
  if (tokenIdSearch) {
    search.tokenid = tokenIdSearch;
  }
  return search;
}
