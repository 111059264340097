import { makeStyles } from "@mui/styles";
import { TEXT_ALIGN_CENTER } from "src/tsx/style/globalStyle";

export const listStyles = makeStyles({
  root: {},
  dateChoose: {
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "5px",
  },
  searchInputMain: {
    marginBottom: "10px",
  },
  searchInputLabel: {
    position: "relative",
    top: 10,
  },
  searchInputButton: {
    marginTop: "9px",
    marginLeft: "10px",
  },
  tableTh: {
    fontSize: "18px",
    fontWeight: 700,
    padding: 10,
    ...TEXT_ALIGN_CENTER,
  },
  tableTd: {
    ...TEXT_ALIGN_CENTER,
    fontSize: "16px",
    padding: 2,
  },
  detailDialog: {
    "& .MuiPaper-rounded": {
      maxWidth: "100% !important",
      width: "80vw",
    },
  },
  screenshot: {
    width: "80px",
    height: "100%",
  },
});