// utils/string.ts

// 验证以太坊钱包地址的正则表达式
const ethereumAddressRegex = /^0x[a-fA-F0-9]{40}$/;

/**
 * 验证是否为有效的以太坊钱包地址
 * @param address 要验证的钱包地址
 * @returns 是否为有效的钱包地址
 */
export const isValidEthereumAddress = (address: string): boolean => {
  return ethereumAddressRegex.test(address);
};

/**
 * 验证是否为大于0的整数
 * @param amount 要验证的金额
 * @returns 是否为大于0的整数
 */
export const isPositiveInteger = (amount: number): boolean => {
  return Number.isInteger(amount) && amount > 0;
};

export const toCamelCase = (str) => {
  return str.replace(/_./g, match => match.charAt(1).toUpperCase());
}

export function formatDateString(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}
