import {
    Box,
    Button,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    Fade,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Popper,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import NumberInput from "../../components/atoms/NumberInput";
import { listStyles } from "../game/style";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import { UpdateOfferDto } from "src/ts/interface/offer/interfaces/update-offer.interface";
import MyPagination from "../../components/modules/myPagination";
import { GetOfferDto } from "src/ts/interface/offer/interfaces/get-offer.interface";
import { reqDeleteOffer, reqGetOffer, reqPostEditOffer } from "src/ts/api/offer";
import { CommonResponse } from "src/ts/interface/common.response";
import { formatWallet, formatPrice } from "src/ts/utils/format";
import { getBlockchainExplorerWalletLink } from "src/ts/config/Web3AuthConfig";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";
import { offerDialogState } from "src/ts/stores/slices/dialogOpen";
import { TEXT_ALIGN_CENTER } from "src/tsx/style/globalStyle";
import OfferDetailDialog from "../../components/modules/offer/OfferDetailDialog";
import { getLocalDateTime } from "src/ts/utils/time";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function formatStatus(status: number) {
    if (status === 100) return 'Finish';
    if (status === 0) return 'Pending';
    if (status === 101) return 'Cancel';
    return 'Unknown';
}

const OfferPage = () => {
    const classes = listStyles();
    const [page, setPage] = useState(1);
    // 分页的页数总数
    const [count, setCount] = useState<number>(0);
    // 分页每一页的页数
    const [pageRows, setPageRows] = useState<string>("200");

    const [offers, setOffers] = useState<UpdateOfferDto[]>([]);


    const [userIdSearch, setUserIdSearch] = useState('');
    const [txIdSearch, setTxIdSearch] = useState('');
    const [buyerSearch, setBuyerSearch] = useState('');
    const [priceTypeSearch, setPriceTypeSearch] = useState('');
    const [statusSearch, setStatusSearch] = useState('');
    const [tokenIdSearch, setTokenIdSearch] = useState('');


    const handleSearchButtonClick = async () => {
        setPage(1);
        await getOffers();
    }

    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const getOffers = async () => {
        const getOfferData = new GetOfferDto(
            userIdSearch,
            txIdSearch,
            buyerSearch,
            priceTypeSearch,
            statusSearch,
            tokenIdSearch,
            page,
            parseInt(pageRows),
        );
        const r = await reqGetOffer(getOfferData);

        const result = r.data;
        if (result.code === 200) {
            setOffers(result.data);
            const pageCount = Math.ceil(result.count / parseInt(pageRows));
            setCount(pageCount);
        }
    }

    const saveOfferPrice = async () => {
        const data = {
            id: editOfferId,
            price: editOfferPrice,
            priceType: editOfferPriceType
        };

        const r = await reqPostEditOffer(data);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            getOffers();
            handleClose();
        }
    }

    const deleteOffer = async (id: number) => {
        const r = await reqDeleteOffer({ id: id });
        console.log(r);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            getOffers();
        }
    }

    const [editOfferId, setEditOfferId] = useState(0);
    const [editOfferPrice, setEditOfferPrice] = useState('');
    const [editOfferPriceType, setEditOfferPriceType] = useState('');
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const editOffer = (offer: UpdateOfferDto) => {
        setEditOfferId(offer.id);
        setEditOfferPrice(offer.price);
        setEditOfferPriceType(offer.priceType);
        setOpen(true);
    }

    const getOfferDetail = (offer: UpdateOfferDto) => {
        setDialogTokenId(offer.tokenid);
        setDialogTxid(offer.txid);
        setDialogUid(offer.uid);
        setDialogBuyer(offer.buyer);
        setDialogPrice(offer.price);
        setDialogPriceType(offer.priceType);
        setDialogStatus(offer.status);
        setDialogCreated(offer.created);
        setDialogExpireTime(offer.expireTime);
        setOfferDetailOpen(true);
    }

    const [offerDetailOpen, setOfferDetailOpen] = useRecoilState(offerDialogState);

    const [dialogTokenId, setDialogTokenId] = useState(0);
    const [dialogTxid, setDialogTxid] = useState('');
    const [dialogUid, setDialogUid] = useState(0);
    const [dialogBuyer, setDialogBuyer] = useState('');
    const [dialogPrice, setDialogPrice] = useState('');
    const [dialogPriceType, setDialogPriceType] = useState('');
    const [dialogStatus, setDialogStatus] = useState(0);
    const [dialogCreated, setDialogCreated] = useState(new Date());
    const [dialogExpireTime, setDialogExpireTime] = useState(0);

    const handleOfferDetailClose = () => {
        setOfferDetailOpen(false);
    };

    useEffect(() => {
        getOffers();
    }, [page, pageRows])

    return (
        <div id="Offer" className={classes.root}>

            <div className={classes.searchInputMain}>
                <NumberInput
                    id="TokenId"
                    label="TokenId"
                    variant="standard"
                    value={tokenIdSearch}
                    onChange={(e) => setTokenIdSearch(e.target.value)}
                />
                <TextField
                    id="Buyer"
                    label="Buyer"
                    variant="standard"
                    value={buyerSearch}
                    onChange={(e) => setBuyerSearch(e.target.value)}
                    style={{ marginLeft: 10 }}
                />
                <TextField
                    id="userId"
                    label="userId"
                    variant="standard"
                    value={userIdSearch}
                    onChange={(e) => setUserIdSearch(e.target.value)}
                    style={{ marginLeft: 10 }}
                />
                <FormControl variant="standard" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="PriceType-Label">Price Type</InputLabel>
                    <Select
                        labelId="PriceType"
                        id="PriceType"
                        value={priceTypeSearch}
                        onChange={(e) => {
                            setPriceTypeSearch(e.target.value);
                        }}
                        label="PriceType"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'usdt'}>USDC</MenuItem>
                        <MenuItem value={'divepoint'}>DIVEPOINT</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="Status-Label">Status</InputLabel>
                    <Select
                        labelId="Status"
                        id="Status"
                        value={statusSearch}
                        onChange={(e) => {
                            setStatusSearch(e.target.value);
                        }}
                        label="Status"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={100}>Finish</MenuItem>
                        <MenuItem value={101}>Cancel</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    className={classes.searchInputButton}
                    onClick={handleSearchButtonClick}
                >
                    Search
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>TokenId</TableCell>
                            <TableCell className={classes.tableTh}>Buyer</TableCell>
                            <TableCell className={classes.tableTh}>Price</TableCell>
                            <TableCell className={classes.tableTh}>Expire time</TableCell>
                            <TableCell className={classes.tableTh}>Status</TableCell>
                            <TableCell className={classes.tableTh}>Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offers.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.tokenid}
                                </TableCell>
                                <TableCell scope="row" className={classes.tableTd}>
                                    <a href='javascript:;' onClick={()=>{getOfferDetail(row)}}>({row.uid}){formatWallet(row.buyer)}</a>
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {formatPrice(row.price, row.priceType)} {row.priceType}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getLocalDateTime(new Date(row.expireTime * 1000).toString())}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {formatStatus(row.status)}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <ColorfulButton onClick={() => editOffer(row)}>
                                        Edit
                                    </ColorfulButton>
                                    <ColorfulButton style={{ background: 'red' }} onClick={() => deleteOffer(row.id)}>
                                        Delete
                                    </ColorfulButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <MyPagination
                    count={count}
                    page={page}
                    pageRows={pageRows}
                    handlePageChange={handlePageChange}
                    handlePageRowsChange={handlePageRowsChange}
                />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 600 }}>
                    <h2 id="parent-modal-title">Edit Offer</h2>
                    <TextField
                        disabled
                        margin="normal"
                        required
                        fullWidth
                        name="Id"
                        label="Id"
                        type="text"
                        id="EditOfferId"
                        value={editOfferId}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="Price"
                        label="Price"
                        type="text"
                        id="editPrice"
                        value={editOfferPrice}
                        onChange={(e) => setEditOfferPrice(e.target.value)}
                    />
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="PriceType">Price Type</InputLabel>
                        <Select
                            labelId="PriceType"
                            id="PriceType"
                            value={editOfferPriceType}
                            onChange={(e) => {
                                setEditOfferPriceType(e.target.value);
                            }}
                            label="Price Type"
                        >

                            <MenuItem value={'usdt'}>USDC</MenuItem>
                            <MenuItem value={'divepoint'}>DIVEPOINT</MenuItem>
                        </Select>
                    </FormControl>



                    <ColorfulButton onClick={() => { saveOfferPrice() }}>
                        Save
                    </ColorfulButton>
                    <ColorfulButton onClick={() => { handleClose() }}>
                        Cancel
                    </ColorfulButton>
                </Box>
            </Modal>

            <Dialog
                open={offerDetailOpen}
                onClose={handleOfferDetailClose}
                className={classes.detailDialog}
            >
                <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>Offer Detail</DialogTitle>
                <OfferDetailDialog
                    txid={dialogTxid}
                    uid={dialogUid}
                    buyer={dialogBuyer}
                    tokenid={dialogTokenId}
                    price={dialogPrice}
                    status={dialogStatus}
                    created={dialogCreated}
                    priceType={dialogPriceType}
                    expireTime={dialogExpireTime}
                />
            </Dialog>
        </div>
    )
}
export default OfferPage;