import {
    Button,
    ClickAwayListener,
    Dialog,
    DialogTitle,
    Fade,
    Paper,
    Popper,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, useState } from "react";
import NumberInput from "../../components/atoms/NumberInput";
import { listStyles } from "../game/style";
import { timeToStringFull } from "../../../../ts/extension/date";
import { changeDateToTokyoDate } from '../../../../ts/serveice/date';
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import { UpdateUserConsoleAccountDto } from "src/ts/interface/userConsoleAccount/interfaces/update-user-console-account.interface";
import { useEffect } from "react";
import dayjs from 'dayjs';
import { useConfirmDialog } from 'react-mui-confirm';
import { GetUserConsoleAccountDto } from "src/ts/interface/userConsoleAccount/interfaces/get-userConsoleAccount.interface";
import { reqGetUserConsoleAccount, reqSetState } from "src/ts/api/userConsoleAccount";
import { CommonResponse } from "src/ts/interface/common.response";
import MyPagination from "../../components/modules/myPagination";
import { getLocalDateTime } from "src/ts/utils/time";

const DeveloperPage = () => {
    const classes = listStyles();
    const [page, setPage] = useState(1);
    // 分页的页数总数
    const [count, setCount] = useState<number>(0);
    // 分页每一页的页数
    const [pageRows, setPageRows] = useState<string>("200");
    const [developers, setDevelopers] = useState<UpdateUserConsoleAccountDto[]>([]);
    const confirm = useConfirmDialog();

    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const changeStatus = async (developerId: number, state: number) => {
        console.log(developerId, state);
        confirm({
            title: 'Are you sure you want to confirm this thingy?',
            async onConfirm() {
                console.log("do change Status");
                const result = await reqSetState({ id: developerId, status: state });
                console.log("result", result);
                getDevelopers();
            },
        });
    }

    const getDevelopers = async () => {
        const getUserInfoData = new GetUserConsoleAccountDto(
            page,
            parseInt(pageRows),
        );
        const r = await reqGetUserConsoleAccount(getUserInfoData);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            setDevelopers(result.data);
        }
        const pageCount = Math.ceil(result.count / parseInt(pageRows));
        setCount(pageCount);
    }



    useEffect(() => {
        getDevelopers();
    }, [page, pageRows]);

    return (
        <div id="Admin" className={classes.root}>
            <div className={classes.searchInputMain}>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>Id</TableCell>
                            <TableCell className={classes.tableTh}>User Name</TableCell>
                            <TableCell className={classes.tableTh}>Wallet</TableCell>
                            <TableCell className={classes.tableTh}>Last Login Time</TableCell>
                            <TableCell className={classes.tableTh}>Register Time</TableCell>
                            <TableCell className={classes.tableTh}>Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {developers.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.id}
                                </TableCell>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.username}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {row.wallet}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getLocalDateTime(row.modifyTime.toString())}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getLocalDateTime(row.createTime.toString())}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <ColorfulButton onClick={() => changeStatus(row.id, row.state === 0 ? 1 : 0)}>
                                        {row.state === 0 ? 'Active' : 'Frozen'}
                                    </ColorfulButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <MyPagination
                    count={count}
                    page={page}
                    pageRows={pageRows}
                    handlePageChange={handlePageChange}
                    handlePageRowsChange={handlePageRowsChange}
                />
            </div>
        </div>
    )
}
export default DeveloperPage;