import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { dialogStyles } from "../game/gameDetailDialog/style";
import { ColorfulButton } from "../../atoms/ColorfulButton";
import { useRecoilState } from 'recoil';
import { nftItemDialogState, userInfoDialogState } from '../../../../../ts/stores/slices/dialogOpen';
import { UpdateNftItemDto } from "src/ts/interface/nft/interfaces/update-nftItem.interface";
import { reqGetNftItem, reqGetNftItemDetail } from "src/ts/api/nftItem";
import { CommonResponse } from "src/ts/interface/common.response";
import { getBlockchainExplorerWalletLink } from "src/ts/config/Web3AuthConfig";
import { formatPrice } from "src/ts/utils/format";

interface NftItemDetailDialogProp {
    nftItemId: number;
}

const NftItemDetailDialog = (prop: NftItemDetailDialogProp) => {
    const classes = dialogStyles();

    const [nftItemDetail, setNftItemDetail] = useState<UpdateNftItemDto>(
        new UpdateNftItemDto()
    );
    const setNftItemDialogState = useRecoilState(nftItemDialogState)[1];

    const getNftItemDetail = async () => {
        const data = { nftItemId: prop.nftItemId };
        const r = await reqGetNftItemDetail(data);
        const result: CommonResponse = r.data;
        if (result.code === 200) {
            setNftItemDetail(result.data[0]);
        }
    };

    useEffect(() => {
        (async () => {
            await getNftItemDetail();
        })();
    }, []);

    const closeDialog = () => {
        setNftItemDialogState(false);
    };
    return (
        <div id="NftItemDetailDialog">
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                ID
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {nftItemDetail.id}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Chain
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {nftItemDetail.chain}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                NFT Info
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                <table className="table-detail-info">
                                    <tr>
                                        <td className="label">Token Id</td><td>{nftItemDetail.tokenId}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Name</td><td>{nftItemDetail.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Description</td><td>{nftItemDetail.description}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Owner</td><td><a target="_blank" href={getBlockchainExplorerWalletLink(nftItemDetail.chain, nftItemDetail.owner)}>{nftItemDetail.owner}</a>  ({nftItemDetail.ownerUid})</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Metadata</td><td>{nftItemDetail.attributes}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">Image</td><td><img src={nftItemDetail.image} alt={nftItemDetail.image} /></td>
                                    </tr>
                                </table>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                GameInfo
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                <table className="table-detail-info">
                                    <tr>
                                        <td className="label">GameId</td><td>{nftItemDetail.gameId}</td>
                                    </tr>
                                    <tr>
                                        <td className="label">GameItemId</td><td>{nftItemDetail.gameItemId}</td>
                                    </tr>
                                </table>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Trade Info
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                <table className="table-detail-info">
                                    <tr>
                                        <td className="label">Price</td><td>{formatPrice(nftItemDetail.price, nftItemDetail.priceType)} {nftItemDetail.priceType}</td>
                                    </tr>
                                </table>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>


            <div className={classes.dialogButtonsMain}>
                <ColorfulButton onClick={closeDialog}>OK</ColorfulButton>
            </div>
        </div>
    );
};

export default NftItemDetailDialog;
