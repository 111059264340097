import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { dialogStyles } from "../game/gameDetailDialog/style";
import { ColorfulButton } from "../../atoms/ColorfulButton";
import { useRecoilState } from 'recoil';
import { relayCallDialogState } from '../../../../../ts/stores/slices/dialogOpen';
interface RelayCallDetailDialogProp {
    chainId: number;
    target: string;
    relayData: string;
    user: string;
    taskState: string;
    transactionHash: string;
    ctime: Date;
}

const RelayCallDetailDialog = (prop: RelayCallDetailDialogProp) => {
    const classes = dialogStyles();
    const setRelayCallDialogState = useRecoilState(relayCallDialogState)[1];


    const closeDialog = () => {
        setRelayCallDialogState(false);
    };
    return (
        <div id="RelayCallDetailDialog">
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                ChainId
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.chainId}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                User
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.user}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Interact Contract
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.target}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Call Data
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                <p style={{wordWrap:'break-word', width:'800px', margin:'0 auto'}}>
                                    {prop.relayData}
                                </p>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Result
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.transactionHash}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Status
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.taskState}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" className={classes.tableTh}>
                                Create Time
                            </TableCell>
                            <TableCell className={classes.tableTd}>
                                {prop.ctime}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.dialogButtonsMain}>
                <ColorfulButton onClick={closeDialog}>OK</ColorfulButton>
            </div>
        </div>
    );
};

export default RelayCallDetailDialog;
