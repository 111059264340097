import { GetReqDto } from "../../common";

export class GetUserInfoDto extends GetReqDto {
  userIdSearch: string;
  usernameSearch: string;

  constructor(
    page: number,
    pageRows: number,
    userIdSearch: string,
    usernameSearch: string
  ) {
    super(page, pageRows);
    this.userIdSearch = userIdSearch;
    this.usernameSearch = usernameSearch;
  }
}

export interface GetUserDetailDto {
  userInfoId: number;
}
