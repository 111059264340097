import { GetReqDto } from '../../common';

export class GetNftMintTaskDto extends GetReqDto {
  consoleAccountIdSearch: string;
  chainSearch: string;
  stateSearch: string;

  constructor(
    consoleAccountIdSearch: string,
    chainSearch: string,
    stateSearch: string,
    page: number, pageRows: number) {
    super(page, pageRows);
    this.consoleAccountIdSearch = consoleAccountIdSearch;
    this.chainSearch = chainSearch;
    this.stateSearch = stateSearch;
  }
}

export interface GetNftMintTaskSearch {
  consoleAccountId?: number;
  chain?: string;
  state?: number;
}

export function getNftMintTaskSearchCondition(getNftMintTaskDto: GetNftMintTaskDto) {
  const search: GetNftMintTaskSearch = {};

  const consoleAccountIdSearch = +getNftMintTaskDto.consoleAccountIdSearch;
  const chainSearch = getNftMintTaskDto.chainSearch;
  const stateSearch = +getNftMintTaskDto.stateSearch;

  if (consoleAccountIdSearch) {
    search.consoleAccountId = consoleAccountIdSearch;
  }
  if (chainSearch) {
    search.chain = chainSearch;
  }
  if (stateSearch) {
    search.state = stateSearch;
  }
  return search;
}
