const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
// dependent on utc plugin
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * 因为数据库里面的数据是日本时间，所以需要把默认的UTC时间
 * 转换为日本时间
 */
export const changeDateToTokyoDate = (
  dateDbFrom: Date | string | null
): Date | null => {
  if (dateDbFrom === null) {
    return null;
  } else {
    const dateDb = new Date(dateDbFrom);
    const d = dayjs.tz(dateDbFrom, "Asia/Tokyo");
    return new Date(d);
  }
};

/**
 * 将date转化为日本时间
 * @param dateDbFrom
 */
export const getJapanDate = (dateDbFrom?: Date | string): Date => {
  let japanDate;
  if (dateDbFrom) {
    japanDate = dayjs(dateDbFrom)
      .add(+9, "h")
      .tz("Asia/Tokyo");
  } else {
    japanDate = dayjs()
      .add(+9, "h")
      .tz("Asia/Tokyo");
  }
  return new Date(japanDate);
};

export const getTodayStart = () => {
  const d = dayjs()
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  return new Date(d);
};
