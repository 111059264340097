import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogTitle,
  Fade,
  Paper,
  Popper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { listStyles } from "../game/style";
import { SelectChangeEvent } from "@mui/material/Select";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import MyPagination from "../../components/modules/myPagination";
import { GetUserInfoDto } from "../../../../ts/interface/userInfo/interfaces/get-userInfo.interface";
import { ResponseUserInfoDto } from "../../../../ts/interface/userInfo/interfaces/response-userInfo.interface";
import { UserInfoResponseEntity } from "../../../../ts/interface/userInfo/entities/userInfo-api.entity";
import { reqUserInfo } from "../../../../ts/api/userInfo";
import { timeToStringFull } from "../../../../ts/extension/date";
import UserInfoDetailDialog from "../../components/modules/userInfo/UserInfoDetailDialog";
import { useRecoilState } from "recoil";
import { TEXT_ALIGN_CENTER } from "../../../style/globalStyle";
import { userInfoDialogState } from "../../../../ts/stores/slices/dialogOpen";
import { findCSChatByUserId } from "../../../../ts/serveice/chat";
import NumberInput from "../../components/atoms/NumberInput";
import { changeDateToTokyoDate } from '../../../../ts/serveice/date';
import '../../../style/app.scss'
import { getUserAvatar } from "src/ts/utils/user";
import { formatWallet } from "src/ts/utils/format";
import { defaultChain } from "src/ts/config/chain";
import { getBlockchainExplorerWalletLink } from "src/ts/config/Web3AuthConfig";
import { getLocalDateTime } from "src/ts/utils/time";
/*  添加 自动生成游戏secret
    修改,一览,详细
 */
const UserInfoPage = () => {
  const classes = listStyles();

  const [page, setPage] = useState(1);
  // 分页的页数总数
  const [count, setCount] = useState<number>(0);
  // 分页每一页的页数
  const [pageRows, setPageRows] = useState<string>("10");
  const [userInfoList, setUserInfoList] = useState<ResponseUserInfoDto[]>([]);
  const [userInfoDetailOpen, setUserInfoDetailOpen] =
    useRecoilState(userInfoDialogState);
  const [userInfoDetailId, setUserInfoDetailId] = useState<number>(1);

  const handlePageRowsChange = (event: SelectChangeEvent) => {
    setPageRows(event.target.value as string);
    setPage(1);
  };
  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  useEffect(() => {
    (async () => {
      await getUserInfoList();
    })();
  }, [page, pageRows]);
  // 当每页大小改变的时候，重新计算页数
  useEffect(() => {
    const pageCount = Math.ceil(count / parseInt(pageRows));
    setCount(pageCount);
    setPage(1);
  }, [pageRows, setCount]);

  const getUserInfoList = async () => {
    const getUserInfoData = new GetUserInfoDto(
      page,
      parseInt(pageRows),
      userIdSearch,
      usernameSearch
    );
    const r = await reqUserInfo(getUserInfoData);
    const result: UserInfoResponseEntity = r.data;
    if (result.code === 200) {
      setUserInfoList(result.data);
    }
    const pageCount = Math.ceil(result.count / parseInt(pageRows));
    setCount(pageCount);
  };

  const getUserInfoDetail = (id: number) => {
    setUserInfoDetailId(id);
    setUserInfoDetailOpen(true);
  };

  const addNewUserInfo = () => {
    setUserInfoDetailId(0);
    setUserInfoDetailOpen(true);
  };
  const handleUserInfoDetailClose = () => {
    setUserInfoDetailOpen(false);
  };

  
  const [userIdSearch, setUserIdSearch] = useState<string>("");
  const [usernameSearch, setUsernameSearch] = useState<string>("");

  const handleUserIdSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUserIdSearch(value);
  };
  const handleUsernameSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsernameSearch(value);
  };
  // 当点击搜索按钮时
  const handleSearchButtonClick = async () => {
    setPage(1);
    await getUserInfoList();
  };
  const changeStatus = async () => {

  }
  useEffect(() => {
    console.log("userInfoList", userInfoList);
  }, [userInfoList])
  return (
    <div id="UserInfo" className={classes.root}>
      <div className={classes.searchInputMain}>
        <NumberInput
          id="UserId"
          label="User Id"
          variant="standard"
          value={userIdSearch}
          onChange={handleUserIdSearchChange}
        />
        <TextField
          id="UserName"
          label="User Name"
          variant="standard"
          value={usernameSearch}
          onChange={handleUsernameSearchChange}
          style={{ marginLeft: 10 }}
        />
        <Button
          variant="contained"
          className={classes.searchInputButton}
          onClick={handleSearchButtonClick}
        >
          Search
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTh}>Id</TableCell>
              <TableCell className={classes.tableTh}></TableCell>
              <TableCell className="left">Email</TableCell>
              <TableCell className="left">User Name</TableCell>
              <TableCell className="right">Wallet</TableCell>
              <TableCell className="right">IP</TableCell>
              <TableCell className={classes.tableTh}>Lst Login Time</TableCell>
              <TableCell className={classes.tableTh}>Create Time</TableCell>
              <TableCell className={classes.tableTh}>Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userInfoList.map((row) => (
              <TableRow key={row.id}>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.id}
                </TableCell>
                <TableCell scope="row" className={classes.tableTd}>
                  <img className="avatar-icon" alt='' src={getUserAvatar(row.profile_photo, row.id)} />
                </TableCell>
                <TableCell className="left">
                  {row.email}
                </TableCell>

                <TableCell className="left">
                  {row.user_name}
                </TableCell>
                <TableCell className="right">
                  <a target="_blank" href={getBlockchainExplorerWalletLink(defaultChain, row.wallet_address)}>{formatWallet(row.wallet_address)}</a>
                </TableCell>
                <TableCell className="right">
                  {row.registerIp}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {getLocalDateTime(row.modify_time.toString())}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {getLocalDateTime(row.create_time.toString())}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  <ColorfulButton onClick={() => getUserInfoDetail(row.id)}>
                    Detail
                  </ColorfulButton>
                  <ColorfulButton onClick={() => changeStatus()}>
                    Frozen
                  </ColorfulButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        <MyPagination
          count={count}
          page={page}
          pageRows={pageRows}
          handlePageChange={handlePageChange}
          handlePageRowsChange={handlePageRowsChange}
        />
      </div>
      <Dialog
        open={userInfoDetailOpen}
        onClose={handleUserInfoDetailClose}
        className={classes.detailDialog}
      >
        <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>User Detail</DialogTitle>
        <UserInfoDetailDialog userInfoId={userInfoDetailId} />
      </Dialog>
    </div>
  );
};

export default UserInfoPage;
