export function getUserAvatar(imageUrl: string, userId: number) {
    if (isImageUrl(imageUrl) || isBase64Image(imageUrl)) {
        return imageUrl;
    } else {
        const random = +userId % 11;
        return `https://cloud.umiverse.io/umi_avatar/${random}.png`;
    }

}

function isImageUrl(url: string) {
    const IMAGE_EXTENSIONS = /\.(png|jpe?g|gif|svg)(\?.*)?$/i;
    if (!IMAGE_EXTENSIONS.test(url)) {
        return false;
    }
    return true;
}

function isBase64Image(str) {
    // 判断是否是以 data:image 开头的 base64 字符串
    return str.startsWith('data:image');
}