import { makeStyles } from "@mui/styles";
import {
  FLEX_ALL_CENTER,
  TEXT_ALIGN_CENTER,
} from "../../../../../style/globalStyle";
import { tableStyle } from "../../../../../style/tableStyle";

export const dialogStyles = makeStyles({
  root: {},
  // @ts-ignore
  tableTh: tableStyle.tableTh,
  // @ts-ignore
  tableTd: tableStyle.tableTd,
  introductionTextArea: {
    "& textarea": {
      whiteSpace: "pre-wrap",
    },
  },
  dialogButtonsMain: {
    ...FLEX_ALL_CENTER,
    justifyContent: "space-evenly",
    marginTop: "10px",
  },
  deleteGameDescription: {
    ...TEXT_ALIGN_CENTER,
    color: "black",
    fontSize: "18px",
  },
  screenshot: {
    width: "295px",
    height: "295px",
  },
  coverImageUrl: {
    // width: '510px',
    width: "80%",
    height: "100%",
  },
  tournamentClassImg: {
    maxHeight: 100,
    // width: "100%",
  },
});