import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import MyPagination from "../../components/modules/myPagination";
import { useRecoilState } from "recoil";
import { listStyles } from "../game/style";
import { timeToStringFull } from "../../../../ts/extension/date";
import { TEXT_ALIGN_CENTER } from "../../../style/globalStyle";
import { nftItemDialogState } from "src/ts/stores/slices/dialogOpen";
import { changeDateToTokyoDate } from "../../../../ts/serveice/date";
import { UpdateNftItemDto } from "src/ts/interface/nft/interfaces/update-nftItem.interface";
import { GetNftItemDto } from "src/ts/interface/nft/interfaces/get-nft.interface";
import { reqGetNftItem } from "src/ts/api/nftItem";
import { CommonResponse } from "src/ts/interface/common.response";
import { formatPrice } from "src/ts/utils/format";
import NftItemDetailDialog from "../../components/modules/nftItem/NftItemDialog";
import { getLocalDateTime } from "src/ts/utils/time";
/*  添加 自动生成 game item
    修改,一览,详细
 */
const NftItemPage = () => {
  const classes = listStyles();

  const [page, setPage] = useState(1);
  // 分页的页数总数
  const [count, setCount] = useState<number>(0);
  // 分页每一页的页数
  const [pageRows, setPageRows] = useState<string>("200");

  const [nftItemList, setNftItemList] = useState<UpdateNftItemDto[]>([]);
  const [nftItemDetailOpen, setNftItemDetailOpen] =
    useRecoilState(nftItemDialogState);

  const [nftItemDetailId, setNftItemDetailId] = useState<number>(1);

  const handlePageRowsChange = (event: SelectChangeEvent) => {
    setPageRows(event.target.value as string);
    setPage(1);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    (async () => {
      await getNftItemList();
    })();
  }, [page, pageRows]);
  // 当每页大小改变的时候，重新计算页数
  useEffect(() => {
    const pageCount = Math.ceil(count / parseInt(pageRows));
    setCount(pageCount);
    setPage(1);
  }, [pageRows, setCount]);

  const [consoleAccountIdSearch, setConsoleAccountIdSearch] = useState('');
  const [chainSearch, setChainSearch] = useState('');
  const [tradeStateSearch, setTradeStateSearch] = useState('');
  const [tokenIdSearch, setTokenIdSearch] = useState('');
  const [ownerUidSearch, setOwnerUidSearch] = useState('');
  const [ownerSearch, setOwnerSearch] = useState('');
  const [gameIdSearch, setGameIdSearch] = useState('');
  const [gameItemIdSearch, setGameItemIdSearch] = useState('');

  const getNftItemList = async () => {
    const getNftItemData = new GetNftItemDto(
      consoleAccountIdSearch,
      chainSearch,
      tradeStateSearch,
      tokenIdSearch,
      ownerUidSearch,
      ownerSearch,
      gameIdSearch,
      gameItemIdSearch,
      page, parseInt(pageRows));
    const r = await reqGetNftItem(getNftItemData);
    const result: CommonResponse = r.data;
    if (result.code === 200) {
      setNftItemList(result.data);
    }
    const pageCount = Math.ceil(result.count / parseInt(pageRows));
    setCount(pageCount);
  };

  const getNftItemDetail = (id: number) => {
    setNftItemDetailId(id);
    setNftItemDetailOpen(true);
  };

  const handleNftItemDetailClose = () => {
    setNftItemDetailOpen(false);
  };


  return (
    <div id="NftItem" className={classes.root}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTh}>Index</TableCell>
              <TableCell className={classes.tableTh}>Chain</TableCell>
              <TableCell className={classes.tableTh}>TokenId</TableCell>
              <TableCell className={classes.tableTh}>Name</TableCell>
              <TableCell className={classes.tableTh}>
                Picture Url(100*100){" "}
              </TableCell>
              <TableCell className={classes.tableTh}>Owner</TableCell>
              <TableCell className={classes.tableTh}>Price</TableCell>
              <TableCell className={classes.tableTh}>CreateTime</TableCell>
              <TableCell className={classes.tableTh}>Operation</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nftItemList.map((row) => (
              <TableRow key={row.id}>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.id}
                </TableCell>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.chain}
                </TableCell>
                <TableCell scope="row" className={classes.tableTd}>
                  {row.tokenId}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {row.name}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  <img
                    src={row.image}
                    alt={row.image}
                    className={classes.screenshot}
                  />
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {row.owner}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {formatPrice(row.price, row.priceType)} {row.priceType}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {getLocalDateTime(row.createTime.toString())}
                </TableCell>
                <TableCell className={classes.tableTd}>
                  <ColorfulButton onClick={() => getNftItemDetail(row.id)}>
                    Detail
                  </ColorfulButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <MyPagination
          count={count}
          page={page}
          pageRows={pageRows}
          handlePageChange={handlePageChange}
          handlePageRowsChange={handlePageRowsChange}
        />
      </div>
      <Dialog
        open={nftItemDetailOpen}
        onClose={handleNftItemDetailClose}
        className={classes.detailDialog}
      >
        <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>
          NFT Item Detail
        </DialogTitle>
        <NftItemDetailDialog nftItemId={nftItemDetailId} />
      </Dialog>
    </div>
  );
};

export default NftItemPage;
