import React, { ChangeEvent, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { dialogStyles } from "./style";
import HtmlParser from "html-react-parser";
import { ColorfulButton } from "../../../atoms/ColorfulButton";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  reqGetGameDetail,
  reqPostGame,
  reqUpdateGame,
} from "../../../../../../ts/api/game";
import { GameResponseEntity } from "../../../../../../ts/interface/game/entities/game-api.entity";
import { ResponseGameDto } from "../../../../../../ts/interface/game/interfaces/response-game.interface";
import { timeToStringFull } from "../../../../../../ts/extension/date";
import { addBrForText } from "../../../../../../ts/serveice/gameProcess";
import { useRecoilState } from "recoil";
import { gameDialogState } from "src/ts/stores/slices/dialogOpen";
import NumberInput from "../../../atoms/NumberInput";
import { changeDateToTokyoDate } from "../../../../../../ts/serveice/date";
import { socket } from "../../../../../../ts/extension/ajax/request";

interface GameDetailDialogProp {
  gameId: number;
}

const GameDetailDialog = (prop: GameDetailDialogProp) => {
  const classes = dialogStyles();
  // 如果是从add过来的
  const newGame = prop.gameId === 0;
  const [gameDetail, setGameDetail] = useState<ResponseGameDto>(
    new ResponseGameDto()
  );
  const [gameDetailUpdating, setGameDetailUpdating] =
    useState<boolean>(newGame);
  const setGameDialogState = useRecoilState(gameDialogState)[1];

  const gameDetailNumberOnly = ["state", "highlightType", "orderWeight"];

  const getGameDetail = async () => {
    if (newGame) {
      const newGame = new ResponseGameDto();
      setGameDetail(newGame);
    } else {
      const gameId = { gameId: prop.gameId };
      const r = await reqGetGameDetail(gameId);
      const result: GameResponseEntity = r.data;
      if (result.code === 200) {
        setGameDetail(result.data[0]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getGameDetail();
    })();
  }, []);

  const updateGameDetail = async () => {
    try {
      // 如果是正在编辑
      if (gameDetailUpdating) {
        // 更新和作成是不同的URL
        if (newGame) {
          const r = await reqPostGame(gameDetail);
          const result = r.data;
          setGameDetail(result.data[0]);
        } else {
          // 更新游戏
          const r = await reqUpdateGame(gameDetail);
          const result = r.data;
          setGameDetail(result.data[0]);
        }
        setGameDialogState(false);
      }
    } catch (e) {
      alert("error");
    } finally {
      socket.emit("getGameListServer", {});
    }
    setGameDetailUpdating(!gameDetailUpdating);
  };

  const handleChangeGameDetail = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const inputName = e.target.name;
    const gameDetailNew = JSON.parse(JSON.stringify(gameDetail));
    gameDetailNew[inputName] = gameDetailNumberOnly.includes(inputName)
      ? parseInt(inputValue)
      : inputValue;
    setGameDetail(gameDetailNew);
  };
  const handleIntroductionChange = (e: any) => {
    const inputValue = e.target.value;
    const gameDetailNew = JSON.parse(JSON.stringify(gameDetail));
    gameDetailNew.introduction = inputValue;
    setGameDetail(gameDetailNew);
  };
  const handleChangeGameDetailState = (e: SelectChangeEvent) => {
    const inputValue = +e.target.value;
    const gameDetailNew = JSON.parse(JSON.stringify(gameDetail));
    gameDetailNew.state = inputValue;
    setGameDetail(gameDetailNew);
  };
  return (
    <div id="GameDetailDialog">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {!newGame && (
              <TableRow>
                <TableCell component="th" className={classes.tableTh}>
                  Game Id
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {gameDetail.id}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Name
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.name}
                    name="name"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.name
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Short Name
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.shortName}
                    name="shortName"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.shortName
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Category
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.category}
                    name="category"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.category
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Slogan
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.slogan}
                    name="slogan"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.slogan
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Description
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.description}
                    name="description"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.description
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Introduction
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.introduction}
                    className={classes.introductionTextArea}
                    fullWidth
                    multiline
                    onChange={handleIntroductionChange}
                    id="fullWidth"
                  />
                ) : (
                  HtmlParser(addBrForText(gameDetail.introduction))
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Icon Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.iconUrl}
                    name="iconUrl"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  <img src={gameDetail.iconUrl} alt={gameDetail.iconUrl} />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Favicon Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.faviconUrl}
                    name="faviconUrl"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  <img
                    src={gameDetail.faviconUrl}
                    alt={gameDetail.faviconUrl}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Screenshot
                <br />
                (295*295)
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.screenshot}
                    name="screenshot"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  <img
                    src={gameDetail.screenshot}
                    className={classes.screenshot}
                    alt={gameDetail.screenshot}
                  />
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Email
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.email}
                    name="email"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.email
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Company
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.company}
                    name="company"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.company
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Twitter
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.twitter}
                    name="twitter"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.twitter
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                facebook
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.facebook}
                    name="facebook"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.facebook
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Line
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.line}
                    name="line"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.line
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Cdn Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.cdnUrl}
                    name="cdnUrl"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.cdnUrl
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Game Server Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.gameServerUrl}
                    name="gameServerUrl"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.gameServerUrl
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Callback Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.callbackUrl}
                    name="callbackUrl"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.callbackUrl
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                State
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <Select
                    value={gameDetail.state.toString()}
                    name="state"
                    onChange={handleChangeGameDetailState}
                    displayEmpty
                  >
                    <MenuItem value={0}>Terminate</MenuItem>
                    <MenuItem value={1}>Activate</MenuItem>
                  </Select>
                ) : (
                  null
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Highlight Type
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <NumberInput
                    value={gameDetail.highlightType}
                    name="highlightType"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.highlightType
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Order Weight
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <NumberInput
                    value={gameDetail.orderWeight}
                    name="orderWeight"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.orderWeight
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Secret
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.secret}
                    name="secret"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  gameDetail.secret
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Cover Image Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameDetailUpdating ? (
                  <TextField
                    value={gameDetail.coverImageUrl}
                    name="coverImageUrl"
                    onChange={handleChangeGameDetail}
                    variant="standard"
                  />
                ) : (
                  <img
                    src={gameDetail.coverImageUrl}
                    className={classes.coverImageUrl}
                    alt={gameDetail.coverImageUrl}
                  />
                )}
              </TableCell>
            </TableRow>
            {!newGame && (
              <TableRow>
                <TableCell component="th" className={classes.tableTh}>
                  Modify Time
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {timeToStringFull(
                    changeDateToTokyoDate(gameDetail.modifyTime)
                  )}
                </TableCell>
              </TableRow>
            )}
            {!newGame && (
              <TableRow>
                <TableCell component="th" className={classes.tableTh}>
                  Create Time
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {timeToStringFull(
                    changeDateToTokyoDate(gameDetail.createTime)
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.dialogButtonsMain}>
        <ColorfulButton onClick={updateGameDetail}>
          {gameDetailUpdating ? "Confirm" : "Update"}
        </ColorfulButton>
      </div>
    </div>
  );
};

export default GameDetailDialog;
