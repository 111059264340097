// 获取offer list
import { GetOfferDto } from 'src/ts/interface/offer/interfaces/get-offer.interface';
import ajaxRequest, { requestType } from '../../extension/ajax/request';

export const reqGetOffer = async (data: GetOfferDto) =>
    await ajaxRequest('/offer/list', data, requestType.GET);

// 获取offer detail
export const reqGetOfferDetail = async (data: any) =>
    await ajaxRequest(`/offer/${data.id}`, data, requestType.GET);


// Edit Offer price
export const reqPostEditOffer = async (data: any) =>
    await ajaxRequest('/offer/edit/price', data, requestType.POST);

// 删除Offer
export const reqDeleteOffer = async (data: any) =>
    await ajaxRequest(`/offer/delete/${data.id}`, data, requestType.GET);
