export function formatHash(hash: string) {
    const firstSix = hash.substring(0, 8);
    const lastSix = hash.substring(hash.length - 6);

    const result = firstSix + '......' + lastSix;
    return result;
}

export function formatWallet(wallet: string) {
    const firstSix = wallet.substring(0, 6);
    const lastSix = wallet.substring(wallet.length - 4);

    const result = firstSix + '......' + lastSix;
    return result;
}

export const fromEther = (value: number) => Math.round(value * 1e18);
export const toEther = (value: number) => value / 1e18;

export function formatPrice(price: string, priceType: string) {
    console.log(Number(price), priceType);
    if (priceType === 'divepoint') {
        return price;
    } else {
        return toEther(Number(price));
    }
}