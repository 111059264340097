/**
 * @fileOverview 封装Ajax方法。
 * @author sun
 * @version 1.0
 */

import axios from "axios";
import {backToLogin} from '../../router/url';
import io from 'socket.io-client';

export const requestType = {
  POST: "POST",
  GET: "GET",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const host = process.env.REACT_APP_HOST;
// export const host = "https://fujiyama-api.kokonats.club";
//接続
export const socket = io(host);
export default async function ajaxRequest(url = "", data = {}, type = "GET") {
  const ajaxaxiosInstance = axios.create({
    baseURL: host,
    timeout: 20000,
  });
  // http request 拦截器
  ajaxaxiosInstance.interceptors.request.use(
    (config) => {
      // 发起请求前做什么？如显示加载中的动画、对重复请求的二次拦截或给header添加token
      config.headers["Cache-Control"] = "no-cache";
      // config.params = {...config.params, timestamp: Date.now()};
      // config.headers["Pragma"] = "no-cache";
      //判断token是否存在
      const userToken = localStorage.getItem("kokoConsoleToken");
      if (userToken) {
        //将token设置成请求头
        config.headers["Authorization"] = "Bearer " + userToken;
        // 以后要删除
        // console.log(config);
      }
      return config;
    },
    (error) => {
      // 请求错误如何处理？如请求超时的处理
      console.log("ajaxaxiosInstance.interceptors.request Error" + error);
    }
  );

  // http response 拦截器
  ajaxaxiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log(error);
      if (error.response.status === 401) {
        backToLogin();
      }
      return Promise.reject(
        "ajaxaxiosInstance.interceptors.response Error" + error
      );
    }
  );

  // 如果是get方法
  if (type.toUpperCase() === "GET") {
    return await ajaxaxiosInstance.get(url, {
      params: data,
    });
  } else if (type.toUpperCase() === "POST") {
    return await ajaxaxiosInstance.post(url, data);
  } else if (type.toUpperCase() === "DELETE") {
    return await ajaxaxiosInstance.delete(url, data);
  } else if (type.toUpperCase() === "PATCH") {
    return await ajaxaxiosInstance.patch(url, data);
  }
  return await ajaxaxiosInstance.post(url, data);
}
