import React, { ChangeEvent, useEffect, useState } from "react";
import { dialogStyles } from "./style";
import { ColorfulButton } from "../../../atoms/ColorfulButton";
import TextField from "@mui/material/TextField";

import { useRecoilState } from "recoil";
import { ResponseGameItemDto } from "../../../../../../ts/interface/gameItem/interfaces/response-gameItem.interface";
import { gameItemDialogState } from "../../../../../../ts/stores/slices/dialogOpen";
import {
  reqGetGameItemDetail,
  reqPostGameItem,
  reqUpdateGameItem,
} from "../../../../../../ts/api/gameItem";
import { GameItemResponseEntity } from "../../../../../../ts/interface/gameItem/entities/gameItem-api.entity";
import { timeToStringFull } from "../../../../../../ts/extension/date";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import NumberInput from "../../../atoms/NumberInput";
import { changeDateToTokyoDate } from "../../../../../../ts/serveice/date";
import {socket} from '../../../../../../ts/extension/ajax/request';

interface GameItemDetailDialogProp {
  gameItemId: number;
}

const gameItemDetailNumberOnly = ["gameId", "kokoPrice"];
const GameItemDetailDialog = (prop: GameItemDetailDialogProp) => {
  const classes = dialogStyles();
  // 如果是从add过来的
  const newGameItem = prop.gameItemId === 0;
  const [gameItemDetail, setGameItemDetail] = useState<ResponseGameItemDto>(
    new ResponseGameItemDto()
  );
  const [gameItemDetailUpdating, setGameItemDetailUpdating] =
    useState<boolean>(newGameItem);

  const setGameItemDialogState = useRecoilState(gameItemDialogState)[1];

  const getGameItemDetail = async () => {
    if (newGameItem) {
      const newGameItem = new ResponseGameItemDto();
      setGameItemDetail(newGameItem);
    } else {
      const gameItemId = { gameItemId: prop.gameItemId };
      const r = await reqGetGameItemDetail(gameItemId);
      const result: GameItemResponseEntity = r.data;
      if (result.code === 200) {
        setGameItemDetail(result.data[0]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getGameItemDetail();
    })();
  }, []);

  const updateGameItemDetail = async () => {
    try {
      // 如果是正在编辑
      if (gameItemDetailUpdating) {
        // 更新和作成是不同的URL
        if (newGameItem) {
          const r = await reqPostGameItem(gameItemDetail);
          const result = r.data;
          setGameItemDetail(result.data[0]);
        } else {
          // 更新GameItem
          const r = await reqUpdateGameItem(gameItemDetail);
          const result = r.data;
          setGameItemDetail(result.data[0]);
        }
        setGameItemDialogState(false);
      }
    } catch (e) {
      console.log(e);
    }finally {
      socket.emit("getGameItemListServer", {});
    }
    setGameItemDetailUpdating(!gameItemDetailUpdating);
  };

  const handleChangeGameItemDetail = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const inputName = e.target.name;

    const gameItemDetailNew = JSON.parse(JSON.stringify(gameItemDetail));
    gameItemDetailNew[inputName] = gameItemDetailNumberOnly.includes(inputName)
      ? parseInt(inputValue)
      : inputValue;
    setGameItemDetail(gameItemDetailNew);
  };

  return (
    <div id="GameItemDetailDialog">
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {!newGameItem && (
              <TableRow>
                <TableCell component="th" className={classes.tableTh}>
                  Id
                </TableCell>
                <TableCell className={classes.tableTd}>
                  {gameItemDetail.id}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Name
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameItemDetailUpdating ? (
                  <TextField
                    value={gameItemDetail.name}
                    name="name"
                    onChange={handleChangeGameItemDetail}
                    variant="standard"
                  />
                ) : (
                  gameItemDetail.name
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Game Id
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameItemDetailUpdating ? (
                  <NumberInput
                    value={gameItemDetail.gameId}
                    name="gameId"
                    onChange={handleChangeGameItemDetail}
                    variant="standard"
                  />
                ) : (
                  <div>
                    {gameItemDetail.gameId} ({gameItemDetail.gameName})
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" className={classes.tableTh}>
                Picture Url
              </TableCell>
              <TableCell className={classes.tableTd}>
                {gameItemDetailUpdating ? (
                  <TextField
                    value={gameItemDetail.pictureUrl}
                    name="pictureUrl"
                    onChange={handleChangeGameItemDetail}
                    variant="standard"
                  />
                ) : (
                  <img
                    src={gameItemDetail.pictureUrl}
                    alt={gameItemDetail.pictureUrl}
                  />
                )}
              </TableCell>
            </TableRow>

            {!newGameItem && (
              <>
                <TableRow>
                  <TableCell component="th" className={classes.tableTh}>
                    Modify Time
                  </TableCell>
                  <TableCell className={classes.tableTd}>
                    {timeToStringFull(
                      changeDateToTokyoDate(gameItemDetail.modifyTime)
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" className={classes.tableTh}>
                    Create Time
                  </TableCell>
                  <TableCell className={classes.tableTd}>
                    {timeToStringFull(
                      changeDateToTokyoDate(gameItemDetail.createTime)
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.dialogButtonsMain}>
        <ColorfulButton onClick={updateGameItemDetail}>
          {gameItemDetailUpdating ? "Confirm" : "Update"}
        </ColorfulButton>
      </div>
    </div>
  );
};

export default GameItemDetailDialog;
