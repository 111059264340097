// 获取relay call list
import { GetRelayCallDto } from 'src/ts/interface/relayCall/interfaces/get-relayCall.interface';
import ajaxRequest, {requestType} from '../../extension/ajax/request';

export const reqGetRelayCall = async (data: GetRelayCallDto) =>
  await ajaxRequest('/relaycall/list', data, requestType.GET);

// 获取relay call detail
export const reqGetRelayCallDetail = async (data: any) =>
  await ajaxRequest(`/relaycall/${data.id}`, data, requestType.GET);

// 删除 relay call
export const reqDeleteRelayCall = async (data: any) =>
  await ajaxRequest(`/relaycall/delete/${data.id}`, data, requestType.GET);
