export const POLYGON = 'polygon';
export const IMMUTABLE_ZKEVM = "imx_zkevm";

export const LoginMethod = {
    Venly: 'venly',
    Immutable: 'Immutable',
    Password: 'password',
    Wallet: "wallet",
    WalletConnect: "wallet_connect"
};

export const PriceTypeEnum = {
    usdt: "usdt",
    usdc: "usdc",
    divepoint: "divepoint"
}

export const ChainEnum = {
    polygon: "polygon",
    arbitrum: "arbitrum",
    arbitrumNova: "arbitrumNova",
    imx: "imx",
    mumbai: "mumbai",
    avac: "avac"
}

export const ContractNameEnum = {
    market: "market",
    presale: "presale",
    nft: "nft",
    payment: "payment",
    verify: "verify"
}