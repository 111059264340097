import {
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { listStyles } from "../game/style";
import { ColorfulButton } from "../../components/atoms/ColorfulButton";
import { UpdateTxsDto } from "src/ts/interface/txs/interfaces/update-txs.interface";
import MyPagination from "../../components/modules/myPagination";
import { reqTxs } from "src/ts/api/txs/txs";
import { GetTxsDto } from "src/ts/interface/txs/interfaces/get-txs.interface";
import {  getSupportChains, getBlockchainExplorerTransactionLink, getBlockchainExplorerWalletLink } from "src/ts/config/Web3AuthConfig";
import { formatHash, formatWallet } from "src/ts/utils/format";
import { useRecoilState } from "recoil";
import { offerDialogState } from "src/ts/stores/slices/dialogOpen";
import { TEXT_ALIGN_CENTER } from "src/tsx/style/globalStyle";
import TxsDetailDialog from "../../components/modules/txs/TxsDetailDialog";
import { ChainData } from "src/ts/config/chain";
import { getLocalDateTime } from "src/ts/utils/time";

function formatStatus(status: number) {
    if (status === 100) return 'Finish';
    if (status === 0) return 'Processing';
    if (status === -1) return 'Cancel';
    return 'Unknown';
}
const TransactionsPage = () => {
    const classes = listStyles();
    const [page, setPage] = useState(1);
    // 分页的页数总数
    const [count, setCount] = useState<number>(0);
    // 分页每一页的页数
    const [pageRows, setPageRows] = useState<string>("200");

    const [transactions, setTransactions] = useState<UpdateTxsDto[]>([]);

    const [userIdSearch, setUserIdSearch] = useState<string>("");
    const [txIdSearch, setTxIdSearch] = useState<string>("");
    const [fromSearch, setFromSearch] = useState<string>("");
    const [toSearch, setToSearch] = useState<string>("");
    const [statusSearch, SetStatusSearch] = useState<string>("");
    const [chainSearch, setChainSearch] = useState<string>("");
    const [chains, setChains] = useState([]);

    const handleSearchButtonClick = async () => {
        setPage(1);
        await getTransactions();
    }

    const handlePageRowsChange = (event: SelectChangeEvent) => {
        setPageRows(event.target.value as string);
        setPage(1);
    };
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const getTransactions = async () => {
        /**
         *  userIdSearch: string,
            txIdSearch: string,
            fromSearch: string,
            toSearch: string,
            statusSearch: string,
            chainSearch: string,
         */
        const getTxsData = new GetTxsDto(
            userIdSearch,
            txIdSearch,
            fromSearch,
            toSearch,
            statusSearch,
            chainSearch,
            page,
            parseInt(pageRows),
        );
        const r = await reqTxs(getTxsData);

        const result = r.data;
        if (result.code === 200) {
            setTransactions(result.data);
            const pageCount = Math.ceil(result.count / parseInt(pageRows));
            setCount(pageCount);
        }
    }

    const supportChains = () => {
        const chains = getSupportChains();
        console.log("chains", chains);
        setChains(chains);
    }

    const getTransactionDetail = (txs: UpdateTxsDto) => {
        setDialogId(txs.id);
        setDialogChain(txs.chain);
        setDialogCreated(txs.created);
        setDialogFrom(txs.from);
        setDialogStatus(txs.status);
        setDialogTo(txs.to);
        setDialogTxid(txs.txid);
        setDialogUid(txs.uid);
        setTxsDetailOpen(true);
    }



    const [txsDetailOpen, setTxsDetailOpen] = useRecoilState(offerDialogState);

    const [dialogId, setDialogId] = useState(0);
    const [dialogTxid, setDialogTxid] = useState('');
    const [dialogUid, setDialogUid] = useState(0);
    const [dialogFrom, setDialogFrom] = useState('');
    const [dialogTo, setDialogTo] = useState('');
    const [dialogStatus, setDialogStatus] = useState(0);
    const [dialogCreated, setDialogCreated] = useState(new Date());
    const [dialogChain, setDialogChain] = useState('');

    const handleTxsDetailClose = () => {
        setTxsDetailOpen(false);
    };

    useEffect(() => {
        supportChains();
        getTransactions();
    }, [page, pageRows]);

    return (
        <div id="Transactions" className={classes.root}>
            <div className={classes.searchInputMain}>
                <TextField
                    id="UserId"
                    label="UserId"
                    variant="outlined"
                    value={userIdSearch}
                    onChange={(e) => { setUserIdSearch(e.target.value) }}
                    style={{ marginLeft: 10 }}
                />
                <TextField
                    id="txIdSearch"
                    label="Hash"
                    variant="outlined"
                    value={txIdSearch}
                    onChange={(e) => { setTxIdSearch(e.target.value) }}
                    style={{ marginLeft: 10 }}
                />
                <TextField
                    id="From"
                    label="From"
                    variant="outlined"
                    value={fromSearch}
                    onChange={(e) => { setFromSearch(e.target.value) }}
                    style={{ marginLeft: 10 }}
                />
                <TextField
                    id="To"
                    label="To"
                    variant="outlined"
                    value={toSearch}
                    onChange={(e) => { setToSearch(e.target.value) }}
                    style={{ marginLeft: 10 }}
                />
                <FormControl variant="outlined" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Chain</InputLabel>
                    <Select
                        labelId="Chain"
                        id="Chain"
                        value={chainSearch}
                        onChange={(e) => {
                            setChainSearch(e.target.value);
                        }}
                        label="Chain"
                    >
                        <MenuItem value="" key="">
                            <em>None</em>
                        </MenuItem>
                        {chains.map((row) => (
                            <MenuItem key={row.chainId} value={row.chainName}><em>{row.displayName}</em></MenuItem>
                        ))};
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ marginLeft: 1, minWidth: 120 }}>
                    <InputLabel id="Status">Status</InputLabel>
                    <Select
                        labelId="Status"
                        id="Status"
                        value={statusSearch}
                        onChange={(e) => {
                            SetStatusSearch(e.target.value);
                        }}
                        label="Status"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={0}>Processing</MenuItem>
                        <MenuItem value={100}>Finish</MenuItem>
                        <MenuItem value={-1}>Cancel</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    className={classes.searchInputButton}
                    onClick={handleSearchButtonClick}
                >
                    Search
                </Button>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableTh}>User</TableCell>
                            <TableCell className={classes.tableTh}>Chain</TableCell>
                            <TableCell className={classes.tableTh}>Hash</TableCell>
                            <TableCell className={classes.tableTh}>From</TableCell>
                            <TableCell className={classes.tableTh}>To</TableCell>
                            <TableCell className={classes.tableTh}>Create Time</TableCell>
                            <TableCell className={classes.tableTh}>Status</TableCell>
                            <TableCell className={classes.tableTh}>Operation</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.uid}
                                </TableCell>
                                <TableCell scope="row" className={classes.tableTd}>
                                    {row.chain}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <a target="_blank" href={getBlockchainExplorerTransactionLink(row.chain, row.txid)}>{formatHash(row.txid)}</a>
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <a target="_blank" href={getBlockchainExplorerWalletLink(row.chain, row.from)}>{formatWallet(row.from)}</a>
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <a target="_blank" href={getBlockchainExplorerWalletLink(row.chain, row.to)}>{formatWallet(row.to)}</a>
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {getLocalDateTime(row.created.toString())}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    {formatStatus(row.status)}
                                </TableCell>
                                <TableCell className={classes.tableTd}>
                                    <ColorfulButton onClick={() => getTransactionDetail(row)}>
                                        Detail
                                    </ColorfulButton>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div>
                <MyPagination
                    count={count}
                    page={page}
                    pageRows={pageRows}
                    handlePageChange={handlePageChange}
                    handlePageRowsChange={handlePageRowsChange}
                />
            </div>


            <Dialog
                open={txsDetailOpen}
                onClose={handleTxsDetailClose}
                className={classes.detailDialog}
            >
                <DialogTitle style={{ ...TEXT_ALIGN_CENTER }}>Transaction Detail</DialogTitle>
                <TxsDetailDialog
                    id={dialogId}
                    txid={dialogTxid}
                    uid={dialogUid}
                    from={dialogFrom}
                    to={dialogTo}
                    status={dialogStatus}
                    created={dialogCreated}
                    chain={dialogChain}
                />
            </Dialog>
        </div>
    )
}

export default TransactionsPage;