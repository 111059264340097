// 获取gameItem list
import ajaxRequest, { requestType } from '../../extension/ajax/request';
import { GetGameItemDto } from '../../interface/gameItem/interfaces/get-gameItem.interface';

export const reqGetGameItem = async (data: GetGameItemDto) =>
  await ajaxRequest('/gameItem/list', data, requestType.GET);

// 获取gameItem detail
export const reqGetGameItemDetail = async (data: any) =>
  await ajaxRequest(`/gameItem/${data.gameItemId}`, data, requestType.GET);

// 作成gameItem
export const reqPostGameItem = async (data: any) =>
  await ajaxRequest('/gameItem', data, requestType.POST);

// 删除gameItem
export const reqDeleteGameItem = async (data: any) =>
  await ajaxRequest(`/gameItem/${data.gameItemId}`, data, requestType.DELETE);

// 更新gameItem
export const reqUpdateGameItem = async (data: any) =>
  await ajaxRequest(`/gameItem/${data.id}`, data, requestType.PATCH);

export const reqGetGameItemSetState = async (data: any) =>
  await ajaxRequest(`/gameItem/setState/${data.gameItemId}/${data.state}`, data, requestType.GET);