import {BaseGameDto} from '../entities/game.entity';

export class ResponseGameDto extends BaseGameDto {
  id: number;

  constructor() {
    super();
    this.id = 0;
  }
}
