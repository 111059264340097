// 获取user console account list
import { GetUserConsoleAccountDto } from 'src/ts/interface/userConsoleAccount/interfaces/get-userConsoleAccount.interface';
import ajaxRequest, {requestType} from '../../extension/ajax/request';

export const reqGetUserConsoleAccount = async (data: GetUserConsoleAccountDto) =>
  await ajaxRequest('/userconsoleaccount/list', data, requestType.GET);

// 获取user console account detail
export const reqGetUserConsoleAccountDetail = async (data: any) =>
  await ajaxRequest(`/userconsoleaccount/${data.id}`, data, requestType.GET);

// 删除user console account
export const reqSetState = async (data: any) =>
  await ajaxRequest(`/userconsoleaccount/state/${data.id}/${data.status}`, data, requestType.GET);
