import {TEXT_ALIGN_CENTER} from './globalStyle';

export const tableStyle = {
  tableTh: {
    width: "20%",
    fontWeight: 700,
    fontSize: "16px",
    ...TEXT_ALIGN_CENTER,
  },
  tableTd: {
    width: "80%",
    fontSize: "16px",
    ...TEXT_ALIGN_CENTER,
    "& .MuiFormControl-root": {
      width: "80%",
    },
  },
};
