import { CreateNftItemDto } from './create-nftItem.interface';

export class UpdateNftItemDto extends CreateNftItemDto {
  id: number;

  constructor() {
    super();
    this.id = 0;
  }
}
