// 获取game list
import ajaxRequest, {requestType} from '../../extension/ajax/request';
import {GetGameDto} from '../../interface/game/interfaces/get-game.interface';

export const reqGetGame = async (data: GetGameDto) =>
  await ajaxRequest('/game/list', data, requestType.GET);

// 获取game detail
export const reqGetGameDetail = async (data: any) =>
  await ajaxRequest(`/game/${data.gameId}`, data, requestType.GET);

// 作成game
export const reqPostGame = async (data: any) =>
  await ajaxRequest('/game', data, requestType.POST);

// 删除game
export const reqDeleteGame = async (data: any) =>
  await ajaxRequest(`/game/${data.gameId}`, data, requestType.DELETE);

// 更新game
export const reqUpdateGame = async (data: any) =>
  await ajaxRequest(`/game/${data.id}`, data, requestType.PATCH);

export const reqPostSendDivepoints = async (data: any) =>
  await ajaxRequest('/game/divepoint/inc', data, requestType.POST);
