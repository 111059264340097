import { BaseGameItemDto } from "../entities/gameItem.entity";

export class ResponseGameItemDto extends BaseGameItemDto {
  id: number;
  gameName: string;

  constructor() {
    super();
    this.id = 0;
    this.gameName = "";
  }
}
