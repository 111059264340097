export class BaseGameItemDto {
  gameId: number;
  kokoPrice: number;
  name: string;
  pictureUrl: string;
  modifyTime: Date;
  createTime: Date;

  constructor() {

    this.gameId = 0;
    this.kokoPrice = 0;
    this.name = "";
    this.pictureUrl = "";
    this.modifyTime = new Date();
    this.createTime = new Date();
  }
}
